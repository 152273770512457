import { Model } from "@vuex-orm/core";

export default class Polygon extends Model {
  static entity = "polygons";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      coordinates: this.attr([]),
    };
  }
}
