import HistoricalItem from "./HistoricalItem";

export default class Pollution extends HistoricalItem {
  static entity = "pollution";
  static primaryKey = "uuid";

  static fields() {
    return {
      impactedEnvironmentsList: this.attr([]),
      estimatedDepth: this.attr(),
      description: this.attr(),
    };
  }
}
