import { Model } from "@vuex-orm/core";
export default class SampleEnvironment extends Model {
  static entity = "sampleEnvironment";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SampleEnvironment";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      plantsSampleReference: this.attr(""),
      pollutedEnvironments: this.attr(""),
      associatedSampleReference: this.attr(""),
    };
  }
}
