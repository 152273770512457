import {
  Agency,
  Material as BaseMaterial,
} from "@socotec.io/socio-vue-components";
import store from "@/store";

export default class Material extends BaseMaterial {
  static entity = "material";
  static primaryKey = "uuid";
  static baseEntity = "baseMaterial";

  static fields() {
    return {
      ...super.fields(),
      agencyUuid: this.attr(""),
      agencyCode: this.attr(""),
      agency: this.belongsTo(Agency, "agencyUuid", "uuid"),
      locality: this.attr(""),
      packs: this.attr(),
      typologies: this.attr(),
      designation: this.string(),
      company: this.attr(),
    };
  }

  static async beforeCreate(model) {
    if (model.agencyUuid) {
      const agencyExist = Agency.query()
        .where("uuid", model.agencyUuid)
        .first();

      if (!agencyExist) {
        try {
          await store.dispatch("user/fetchAgencyByUuid", model.agencyUuid);
        } catch (e) {
          console.error(e);
        }
      }
    }
  }
}
