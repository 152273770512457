import { Model } from "@vuex-orm/core";
export default class Complaint extends Model {
  static entity = "complaint";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      isEnvironmentsComplaint: this.attr(false),
      areasConcernedList: this.attr([]),
      complaintText: this.attr(""),
    };
  }
}
