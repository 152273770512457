import { Model } from "@vuex-orm/core";

export default class UndergroundWaterAnalyticalProgram extends Model {
  static entity = "undergroundWaterAnalyticalProgram";
  static primaryKey = "uuid";

  static protoMessagePrefix = "UndergroundWaterAnalyticalProgram";

  static fields() {
    return {
      uuid: this.attr(""),
      reportQuestionnaire: this.attr(""),
      measurePoint: this.attr(""),
      sampleNumber: this.attr(""),
      substancesSought: this.attr(""),
    };
  }
}
