import { Model } from "@vuex-orm/core";

export default class Tag extends Model {
  static entity = "tag";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(null),
      name: this.attr(),
      slug: this.attr(),
    };
  }
}
