const MaterialsList = () =>
  import(
    /* webpackChunkName: "MaterialsList" */ "@/components/ssp/materials/MaterialsList"
  );
export default [
  {
    path: "",
    name: "materialsList",
    component: MaterialsList,
  },
];
