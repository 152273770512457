import { Model } from "@vuex-orm/core";
export default class Site extends Model {
  static entity = "sites";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      siteAccess: this.attr(),
      constraintsList: this.attr([]),
      project: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      immediateDangersList: this.attr([]),
      visitSection: this.attr(0),
      overallEnvironmentList: this.attr([]),
      isNonWaterproofedSurfaces: this.attr(false),
      surfacesNatureList: this.attr([]),
      isStoragePresent: this.attr(false),
      storageNature: this.attr(""),
      locationPlan: this.attr(null),
      locationPlanName: this.attr(""),
      locationPlanSize: this.attr(""),
      centerLatitude: this.attr(),
      centerLongitude: this.attr(),
      cartographyElementsList: this.attr([]),
    };
  }
}
