import { Model } from "@vuex-orm/core";
export default class Attachments extends Model {
  static entity = "attachments";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      file: this.attr(""),
      createdAt: this.attr(""),
      updatedAt: this.attr(""),
    };
  }
}
