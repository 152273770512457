import { Model } from "@vuex-orm/core";

export default class BasiasBasolSite extends Model {
  static entity = "basiasBasolSite";
  static primaryKey = "uuid";

  static protoMessagePrefix = "BasiasBasolSite";

  static fields() {
    return {
      uuid: this.attr(""),
      reportQuestionnaire: this.attr(""),
      typeOfSite: this.attr(""),
      reference: this.attr(""),
      companyName: this.attr(""),
      addressAndLocation: this.attr(""),
      activityDateText: this.attr(""),
      remark: this.attr(""),
    };
  }
}
