const OutDoorSpaces = () =>
  import(
    /* webpackChunkName: "MissionReport" */
    "@/components/ssp/missions/mission/site/partials/OutdoorSpaces"
  );
const PollutedEnvironments = () =>
  import(
    /* webpackChunkName: "MissionReport" */
    "@/components/ssp/missions/mission/site/partials/PollutedEnvironments"
  );
const Complaints = () =>
  import(
    /* webpackChunkName: "MissionReport" */
    "@/components/ssp/missions/mission/site/partials/Complaints"
  );

export default [
  {
    path: "outdoor-space",
    name: "OutdoorSpaces",
    component: OutDoorSpaces,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "polluted-environment",
    name: "PollutedEnvironments",
    component: PollutedEnvironments,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "complaint",
    name: "Complaints",
    component: Complaints,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
];
