const AdditionalInformation = () =>
  import(
    /* webpackChunkName: "AdditionalInformation" */ "@/components/ssp/missions/mission/mission-intervention/AdditionalInformation"
  );

const FloorInformation = () =>
  import(
    /* webpackChunkName: "FloorInformation" */ "@/components/ssp/missions/mission/mission-intervention/envs-information/FloorInformation"
  );

const UnderGroundWaterInformation = () =>
  import(
    /* webpackChunkName: "UnderGroundWaterInformation" */ "@/components/ssp/missions/mission/mission-intervention/envs-information/UnderGroundWaterInformation"
  );

const SoilGazInformation = () =>
  import(
    /* webpackChunkName: "SoilGazInformation" */ "@/components/ssp/missions/mission/mission-intervention/envs-information/SoilGazInformation"
  );

const AmbiantAirInformation = () =>
  import(
    /* webpackChunkName: "AmbiantAirInformation" */ "@/components/ssp/missions/mission/mission-intervention/envs-information/AmbiantAirInformation"
  );

const ExcavatedGroundsInformation = () =>
  import(
    /* webpackChunkName: "ExcavatedGroundsInformation" */ "@/components/ssp/missions/mission/mission-intervention/envs-information/ExcavatedGroundsInformation"
  );

const SurveysList = () =>
  import(
    /* webpackChunkName: "SurveysAndSamples" */ "@/components/ssp/missions/mission/mission-intervention/SurveysList"
  );

const OrdersTracking = () =>
  import(
    /* webpackChunkName: "SurveysAndSamples" */ "@/components/ssp/missions/mission/mission-intervention/OrdersTracking"
  );

const AnalyticProgramSynthesis = () =>
  import(
    /* webpackChunkName: "AnalyticProgramSynthesis" */ "@/components/ssp/missions/mission/mission-intervention/AnalyticProgramSynthesis"
  );

import store from "@/store";
import getPreparationAndMaterials from "@/router/middleware/getPreparationAndMaterials";

export default [
  {
    path: "",
    name: "SurveysAndSamples",
    component: SurveysList,
    beforeEnter: getPreparationAndMaterials(store),
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "program-analytic-synthesis",
    name: "AnalyticProgramSynthesis",
    component: AnalyticProgramSynthesis,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "orders-tracking",
    name: "OrdersTracking",
    component: OrdersTracking,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information",
    name: "AdditionalInformation",
    component: AdditionalInformation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information/floor",
    name: "FloorInformation",
    component: FloorInformation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information/underground-water",
    name: "UnderGroundWaterInformation",
    component: UnderGroundWaterInformation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information/soil-gaz",
    name: "SoilGazInformation",
    component: SoilGazInformation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information/ambiant-air",
    name: "AmbiantAirInformation",
    component: AmbiantAirInformation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "additional-information/excavated-grounds",
    name: "ExcavatedGroundsInformation",
    component: ExcavatedGroundsInformation,
    meta: {
      showSubNavBar: true,
    },
  },
];
