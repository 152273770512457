const MissionsList = () =>
  import(
    /* webpackChunkName: "MissionsList" */ "@/components/ssp/missions/MissionsList"
  );

export default [
  {
    path: "",
    name: "missionsList",
    component: MissionsList,
  },
];
