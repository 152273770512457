import { Model } from "@vuex-orm/core";
export default class Dashboard extends Model {
  static entity = "dashboard";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(""),
      isGroundInvestigated: this.attr(""),
      groundInvestigationStartDate: this.attr(""),
      groundInvestigationEndDate: this.attr(""),
      isUndergroundWaterInvestigated: this.attr(""),
      undergroundWaterPrelevementStartDate: this.attr(""),
      undergroundWaterPrelevementEndDate: this.attr(""),
      undergroundWaterImplantationStartDate: this.attr(""),
      undergroundWaterImplantationEndDate: this.attr(""),
      isSuperficialWaterInvestigated: this.attr(""),
      superficialWaterInvestigationStartDate: this.attr(""),
      superficialWaterInvestigationEndDate: this.attr(""),
      isSedimentInvestigated: this.attr(""),
      sedimentInvestigationStartDate: this.attr(""),
      sedimentInvestigationEndDate: this.attr(""),
      isGasInvestigated: this.attr(""),
      gasImplantationStartDate: this.attr(""),
      gasImplantationEndDate: this.attr(""),
      gasPrelevementStartDate: this.attr(""),
      gasPrelevementEndDate: this.attr(""),
      isAmbientAirInvestigated: this.attr(""),
      ambientAirInvestigationStartDate: this.attr(""),
      ambientAirInvestigationEndDate: this.attr(""),
      isFoodWaterInvestigated: this.attr(""),
      foodWaterInvestigationStartDate: this.attr(""),
      foodWaterInvestigationEndDate: this.attr(""),
      isLandToExcavateInvestigated: this.attr(""),
      landToExcavateInvestigationStartDate: this.attr(""),
      landToExcavateInvestigationEndDate: this.attr(""),
      isVegetalInvestigated: this.attr(""),
      vegetalInvestigationStartDate: this.attr(""),
      vegetalInvestigationEndDate: this.attr(""),
      acknowledgementOfReceiptSent: this.attr(""),
      siteVisitSent: this.attr(""),
      interventionsSent: this.attr(""),
      firstVisitSent: this.attr(""),
      secondVisitSent: this.attr(""),
      offerSent: this.attr(""),
      scheduleChangeSent: this.attr(""),
      samplingPlanSent: this.attr(""),
      analyticalProgramSent: this.attr(""),
      reportDelaySent: this.attr(""),
      cdpReviewRequestSent: this.attr(""),
      supervisionRequestSent: this.attr(""),
      reportSent: this.attr(""),
      teamChangeSent: this.attr(""),
    };
  }
}
