import { Model } from "@vuex-orm/core";
export default class SoilGasInvestigation extends Model {
  static entity = "soilGasInvestigation";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SoilGasInvestigation";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      work: this.attr(""),
      coordinates: this.attr(""),
      depth: this.attr(""),
      positioning: this.attr(""),
      strainedBetween: this.attr(""),
    };
  }
}
