import { Model } from "@vuex-orm/core";

export default class VulnerabilityInformation extends Model {
  static entity = "vulnerabilityInformation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(""),
      vulnerabilityType: this.attr(""),
      sourceList: this.attr([]),
      mapNumber: this.attr(""),
      mapCity: this.attr(""),
      radius: this.attr(),
      bssDocumentsList: this.attr([]),
    };
  }
}
