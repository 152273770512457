import { Model } from "@vuex-orm/core";
export default class PollutedEnvironment extends Model {
  static entity = "pollutedEnvironment";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      isVolatilePowderyProducts: this.attr(false),
      volatilePowderyProductsText: this.attr(""),
      isGaseousSourcesDustEmissions: this.attr(false),
      gaseousSourcesDustEmissionsText: this.attr(""),
      isSurfaceWaterOnSite: this.attr(false),
      siteNatureList: this.attr([]),
      isDirectRejection: this.attr(false),
      directRejectionText: this.attr(""),
      isSurfaceRunoff: this.attr(false),
      surfaceRunoffText: this.attr(""),
      isSurfaceWatersNearby: this.attr(false),
      surfaceWatersNearbyText: this.attr(""),
      nearbyNatureList: this.attr([]),
      extraEnvironmentsList: this.attr([]),
    };
  }
}
