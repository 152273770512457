import { Model } from "@vuex-orm/core";

export default class VulnerabilityUsage extends Model {
  static entity = "vulnerabilityUsage";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(""),
      vulnerability_type: this.attr(""),
      localPlan: this.attr(""),
      pluSite: this.attr(""),
      orientation: this.attr(""),
      pluDocumentsList: this.attr([]),
    };
  }
}
