import { Model } from "@vuex-orm/core";
export default class Cadaster extends Model {
  static entity = "Cadasters";
  static primaryKey = "uuid";
  static fields() {
    return {
      uuid: this.attr(),
      plotNumber: this.attr(null),
      surface: this.attr(null),
      createdAt: this.attr(""),
    };
  }
}
