import { Model } from "@vuex-orm/core";

import Marker from "./Marker";

export default class OtherItem extends Model {
  static entity = "other_items";
  static primaryKey = "uuid";
  static aosType = "OTHER_ITEM";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      usage: this.attr(null),
      temporality: this.attr(null),
      content: this.attr(null),
      remarks: this.attr(null),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      typology: this.attr("OTHER_ITEM"),
      impactedEnvironment: this.attr(null),

      marker: this.morphOne(Marker, "markable_id", "markable_type"),
    };
  }
}
