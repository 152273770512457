import { Model } from "@vuex-orm/core";

export default class MissionArchive extends Model {
  static entity = "missionArchive";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      createdAt: this.attr(),
      project: this.attr(""),
      firstVisitList: this.attr(),
      secondVisitList: this.attr(),
      sendOfferList: this.attr(),
      acknowledgementOfReceiptList: this.attr(),
      scheduleChangeList: this.attr(),
      samplingPlanList: this.attr(),
      analyticalProgramList: this.attr(),
      reportDelayList: this.attr(),
      cdpReviewRequestList: this.attr(),
      supervisionRequestList: this.attr(),
      sendReportList: this.attr(),
      teamChangeList: this.attr(),
    };
  }
}
