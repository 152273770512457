import { Case } from "@socotec.io/socio-vue-components";

export default class SspCase extends Case {
  static entity = "sspCases";
  static baseEntity = super.entity;

  static fields() {
    return {
      ...super.fields(),
      description: this.attr(""),
    };
  }
}
