import { Model } from "@vuex-orm/core";

export default class PurgeUndergroundWatersMeasure extends Model {
  static entity = "purgeUndergroundWatersMeasure";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      purge: this.attr(),
      createdAt: this.attr(),
      startDatetime: this.attr(),
      volumePurged: this.number(),
      piezometricLevel: this.number(),
      temperature: this.number(),
      ph: this.number(),
      conductivity: this.number(),
      correctedRedoxPotential: this.number(),
      dissolvedO2: this.number(),
      temporalObservations: this.string(),
      isVolumePurgedValid: this.boolean(),
      isPhValid: this.boolean(),
      isConductivityValid: this.boolean(),
      isTemperatureValid: this.boolean(),
    };
  }
}
