import { Model } from "@vuex-orm/core";
export default class BssWork extends Model {
  static entity = "bssWork";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      vulnerability: this.attr(),
      number: this.attr(""),
      distance: this.attr(),
      position: this.attr(""),
      geologicalFormation: this.attr(),
    };
  }
}
