import { Report as BaseReport } from "@socotec.io/socio-vue-components";

export default class SspReport extends BaseReport {
  static entity = "ssp_report";
  static primaryKey = "uuid";
  static baseEntity = "reports";

  static fields() {
    return {
      ...super.fields(),
      validationDate: this.string(""),
      isInGeneration: this.attr(false),
    };
  }
}
