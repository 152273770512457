export default (store) => {
  return async (to, from, next) => {
    try {
      const currentDashboardUuid =
        store.getters["missions/currentMission"].dashboardUuid;
      await store.dispatch(
        "missions/retrieveMissionDashboard",
        currentDashboardUuid
      );
      return next();
    } catch (err) {
      console.log(err);
      return next();
    }
  };
};
