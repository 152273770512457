import { Model } from "@vuex-orm/core";

export default class IdentifiedSource extends Model {
  static entity = "identified_source";
  static primaryKey = "uuid";

  static protoMessagePrefix = "IdentifiedSource";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      identifier: this.attr(""),
      nature: this.attr(""),
    };
  }
}
