import HistoricalItem from "./HistoricalItem";

export default class Product extends HistoricalItem {
  static entity = "product";
  static primaryKey = "uuid";

  static fields() {
    return {
      type: this.attr(),
      nature: this.attr(),
      storageConditions: this.attr(),
      useConditions: this.attr(),
      disposalConditions: this.attr(),
    };
  }
}
