import { Model } from "@vuex-orm/core";

export default class UndergroundWaterInvestigationsStructure extends Model {
  static entity = "undergroundWaterInvestigationsStructure";
  static primaryKey = "uuid";

  static protoMessagePrefix = "UndergroundWaterInvestigationsStructure";

  static fields() {
    return {
      uuid: this.attr(""),
      reportQuestionnaire: this.attr(""),
      work: this.attr(""),
      coordinates: this.attr(""),
      structureDepth: this.attr(0),
      crenellatedBetween: this.attr(""),
      waterLevelDepth: this.attr(0),
      piezometerAltitude: this.attr(0),
      piezometricLevel: this.attr(0),
      relativePositioning: this.attr(""),
    };
  }
}
