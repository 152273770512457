import { Model } from "@vuex-orm/core";

export default class ElementaryMission extends Model {
  static entity = "elementaryMission";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      name: this.attr(),
      description: this.attr(),
    };
  }
}
