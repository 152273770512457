import { Model } from "@vuex-orm/core";
export default class ProjectInformation extends Model {
  static entity = "projectInformations";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(),
      dictDeclarationDate: this.attr(),
      dictDeclarationNumber: this.attr(),
      clientRequestDate: this.attr(),
      supportRequestList: this.attr([]),
      requestSynthesis: this.attr(),
      devisReference: this.attr(),
      devisDate: this.attr(),
      usageTypologiesList: this.attr([]),
      futurTargetsList: this.attr([]),
      missionContextList: this.attr([]),
      missionObjectifList: this.attr([]),
      projectFutur: this.attr(),
      projectPlan: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
    };
  }
}
