import BaseEnvModel from "./BaseEnvModel";

export default class AmbientAirInformation extends BaseEnvModel {
  static entity = "ambientAirInformation";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(),
      transportCondition: this.attr("Glacière"),
    };
  }

  setProject(projectUuid) {
    this.project = projectUuid;
  }
}
