import { Model } from "@vuex-orm/core";
export default class RiskyActivity extends Model {
  static entity = "riskyActivity";
  static primaryKey = "uuid";

  static protoMessagePrefix = "RiskyActivity";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      companyName: this.attr(""),
      addressAndLocation: this.attr(""),
      activities: this.attr(""),
      remark: this.attr(""),
    };
  }
}
