const internalMaterial = () =>
  import(
    /* webpackChunkName: "internalMaterial" */ "@/components/ssp/missions/mission/mission-preparation/InternalMaterial"
  );

const externalMaterial = () =>
  import(
    /* webpackChunkName: "externalMaterial" */ "@/components/ssp/missions/mission/mission-preparation/ExternalMaterial"
  );

const AnalysisPreparation = () =>
  import(
    /* webpackChunkName: "AnalysisPreparation" */ "@/components/ssp/missions/mission/mission-preparation/AnalysisPreparation"
  );

export default [
  {
    path: "",
    name: "internalMaterial",
    component: internalMaterial,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "external-material",
    name: "externalMaterial",
    component: externalMaterial,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "analysis-preparation",
    name: "analysisPreparation",
    component: AnalysisPreparation,
    meta: {
      showSubNavBar: true,
    },
  },
];
