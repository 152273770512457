import { Model } from "@vuex-orm/core";
export default class PiezometricLevel extends Model {
  static entity = "PiezometricLevel";
  static primaryKey = "uuid";

  static protoMessagePrefix = "PiezometricLevel";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      bookReference: this.attr(""),
      altitude: this.attr(""),
      date: this.attr(""),
      piezometricLevel: this.attr(""),
      piezometricCoast: this.attr(""),
    };
  }
}
