import { Model } from "@vuex-orm/core";

export default class Storage extends Model {
  static entity = "storage";

  static protoMessagePrefix = "Storage";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      denominationOrLocation: this.attr(""),
      storageTypeList: this.attr([]), // Array<String>
      volume: this.attr(null), // Float
      conditioningList: this.attr([]), // Array<String>
      identifiedProductsList: this.attr([]), // Array<String>
      productsIdentifiedTypeList: this.attr([]), // Array<String>
      containmentSealingAerialLocation: this.attr(""),
      containmentSealingAerialTypeList: this.attr([]), // Array<String>
      hasContainmentSealingAerial: this.attr(null), // Boolean
      state: this.attr(""), // String (State)
      hasTraceOfPollution: this.attr(null), // Boolean
      traceOfPollutionNature: this.attr(""),
      particularRisks: this.attr(""),
    };
  }
}
