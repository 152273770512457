export default (store) => {
  return async (to, from, next) => {
    try {
      const metadata = {
        filters: {
          project_ids: to.params.missionId,
        },
      };

      await store.dispatch("contributors/fetchContributors", metadata);
      await store.dispatch("missions/retrieveMission", to.params.missionId);

      await store.dispatch(
        "preparation/retrievePreparationByMission",
        to.params.missionId
      );
      if (!store.getters["preparation/getCurrentPreparation"].materials)
        return next();
      await store.dispatch("materials/fetchAllMaterials", {
        filters: {
          uuids:
            store.getters["preparation/getCurrentPreparation"]
              .validatedMaterials,
        },
      });
      await store.dispatch(
        "additionalsMaterial/fetchAdditionalsMaterialByPreparation",
        store.getters["preparation/getCurrentPreparation"].uuid
      );

      return next();
    } catch (err) {
      console.log(err);
      return next();
    }
  };
};
