import SocioGrpcApi from "@socotec.io/socio-grpc-api";

const socioGrpcApi = new SocioGrpcApi(
  process.env.VUE_APP_SOCIO_GRPC_API_ENV,
  undefined,
  undefined,
  undefined,
  process.env.VUE_APP_SERVICE_SLUG
);

export default socioGrpcApi;
