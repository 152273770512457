import SspChildrens from "./childrens/ssp-childrens";

import store from "@/store";
import getCurrentUser from "@/router/middleware/getCurrentUser";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const Ssp = () => import(/* webpackChunkName: "Ssp" */ "@/components/Ssp");

export const desktopRouter = [
  {
    path: "/",
    children: SspChildrens,
    component: Ssp,
    beforeEnter: getCurrentUser(store),
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
];
