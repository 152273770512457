import { Model } from "@vuex-orm/core";

export default class LithologicalLayer extends Model {
  static entity = "lithologicalLayer";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      createdAt: this.attr(),
      survey: this.attr(),
      isSuperficial: this.boolean(false),
      types: this.attr(),
      top: this.number(),
      bottom: this.number(),
      colors: this.attr(),
      observations: this.string(),
    };
  }
}
