const MissionIdentityCardCase = () =>
  import(
    /* webpackChunkName: "missionIdentityCardCase" */
    "@/components/ssp/missions/mission/mission-identity-card/MissionIdentityCardCase"
  );

const MissionIdentityCardRelation = () =>
  import(
    /* webpackChunkName: "missionIdentityCardRelation" */
    "@/components/ssp/missions/mission/mission-identity-card/MissionIdentityCardRelation"
  );

const MissionIdentityCardProjectInfo = () =>
  import(
    /* webpackChunkName: "missionIdentityCardProjectInfo" */
    "@/components/ssp/missions/mission/mission-identity-card/MissionIdentityCardProjectInfo"
  );

const MissionIdentityCardSiteInformation = () =>
  import(
    /* webpackChunkName: "missionIdentityCardSiteVisit" */
    "@/components/ssp/missions/mission/mission-identity-card/MissionIdentityCardSiteInformation"
  );

const MissionIdentityCardSiteVisit = () =>
  import(
    /* webpackChunkName: "missionIdentityCardSiteVisit" */
    "@/components/ssp/missions/mission/mission-identity-card/MissionIdentityCardSiteVisit"
  );

export default [
  {
    path: "case",
    name: "case",
    component: MissionIdentityCardCase,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "relation",
    name: "relation",
    component: MissionIdentityCardRelation,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "site-visite",
    name: "site-visite",
    component: MissionIdentityCardSiteVisit,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "information-project",
    name: "information-project",
    component: MissionIdentityCardProjectInfo,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "information-site",
    name: "information-site",
    component: MissionIdentityCardSiteInformation,
    meta: {
      showSubNavBar: true,
    },
  },
];
