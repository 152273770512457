import { Model } from "@vuex-orm/core";
export default class PollutedExtraEnvironment extends Model {
  static entity = "PollutedExtraEnvironment";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      designation: this.attr(""),
      natureImpact: this.attr(""),
      pollutedMainEnvironment: this.attr(""),
      createdAt: this.attr(""),
    };
  }
}
