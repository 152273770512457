const MyProfile = () =>
  import(/* webpackChunkName: "MyProfile" */ "@/components/ssp/MyProfile");

export default [
  {
    path: "edit-profile",
    name: "edit-profile",
    component: MyProfile,
  },
];
