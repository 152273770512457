import { Model } from "@vuex-orm/core";
import getDefaultReportValue from "@/composables/questionnaire/tree-form/report/getDefaultReportValue";

export default class ReportQuestionnaire extends Model {
  static entity = "report_questionnaire";
  static primaryKey = "uuid";

  static protoMessagePrefix = "ReportQuestionnaire";

  static fields() {
    return {
      uuid: this.attr(null),
      project: this.attr(""),
      siteDenomination: this.attr(""),
      siteDescription: this.attr(""),
      localisationPlanTitle: this.attr(
        getDefaultReportValue("localisationPlanTitle")
      ),
      localisationPlan: this.attr(""),
      cadastralPlanTitle: this.attr(
        getDefaultReportValue("cadastralPlanTitle")
      ),
      cadastralPlan: this.attr(""),
      missionContext: this.attr(getDefaultReportValue("missionContext")),
      projectPlan: this.attr(""),
      missionObjective: this.attr(""),
      referenceDocuments: this.attr(
        getDefaultReportValue("referenceDocuments")
      ),
      objectiveReminder: this.attr(getDefaultReportValue("objectiveReminder")),
      // Realization of the visit and person(s) met (A100)
      visitDate: this.attr(""),
      visitContributors: this.attr(getDefaultReportValue("visitContributors")),
      visitPerimeter: this.attr(getDefaultReportValue("visitPerimeter")),
      // Description of the site, activities and facilities identified (A100)
      siteOccupiedBy: this.attr(getDefaultReportValue("siteOccupiedBy")),
      numberOfBuildings: this.attr(null),
      summaryBuildingsDescription: this.attr(
        getDefaultReportValue("summaryBuildingsDescription")
      ),
      buildingsDescription: this.attr(
        getDefaultReportValue("buildingsDescription")
      ),
      nonAccessibleZonesDescription: this.attr(
        getDefaultReportValue("nonAccessibleZonesDescription")
      ),
      summaryFacilitiesAtRisk: this.attr(
        getDefaultReportValue("summaryFacilitiesAtRisk")
      ),
      facilitiesAtRisk: this.attr(getDefaultReportValue("facilitiesAtRisk")),
      siteVisitPlan: this.attr(""),
      siteVisitPlanSource: this.attr(
        getDefaultReportValue("siteVisitPlanSource")
      ),
      siteVisitPhotos: this.attr([]),
      presenceOfPollution: this.attr(
        getDefaultReportValue("presenceOfPollution")
      ),
      // Observed uses and sensitivity of the neighborhood (A100)
      neighborhoodDescription: this.attr(
        getDefaultReportValue("neighborhoodDescription")
      ),
      overheadPhotography: this.attr(""),
      overheadPhotographySource: this.attr(
        getDefaultReportValue("overheadPhotographySource")
      ),
      neighborhoodSensibility: this.attr(
        getDefaultReportValue("neighborhoodSensibility")
      ),
      // Immediate dangers to the environment and public health (A100)
      protectionAndAccessibility: this.attr(""),
      stateOfTheTiles: this.attr(""),
      bareGroundActivity: this.attr(""),
      pollutingSubstancesAndStorage: this.attr(""),
      otherVerifications: this.attr(""),
      protectionAndAccessibilityDanger: this.attr(""),
      stateOfTheTilesDanger: this.attr(""),
      bareGroundActivityDanger: this.attr(""),
      pollutingSubstancesAndStorageDanger: this.attr(""),
      otherVerificationsDanger: this.attr(""),
      // Safety corrective measures (A100)
      safetyCorrectiveMeasures: this.attr(
        getDefaultReportValue("safetyCorrectiveMeasures")
      ),
      // Identification of on-site constraints (A100)
      identificationOfOnsiteConstraints: this.attr(
        getDefaultReportValue("identificationOfOnsiteConstraints")
      ),
      // Information Sources And Documents Consulted (A110)
      dataSourceOfPersonsMet: this.attr(
        getDefaultReportValue("dataSourceOfPersonsMet")
      ),
      consultedDocumentsOfPersonsMet: this.attr(
        getDefaultReportValue("consultedDocumentsOfPersonsMet")
      ),
      consultedDocumentsOfCityHall: this.attr(
        getDefaultReportValue("consultedDocumentsOfCityHall")
      ),
      consultedDocumentsOfMunicipalArchives: this.attr(
        getDefaultReportValue("consultedDocumentsOfMunicipalArchives")
      ),
      consultedDocumentsOfDepartmentalArchives: this.attr(
        getDefaultReportValue("consultedDocumentsOfDepartmentalArchives")
      ),
      consultedDocumentsOfBasiasBasol: this.attr(
        getDefaultReportValue("consultedDocumentsOfBasiasBasol")
      ),
      consultedDocumentsOfNationalGeographicInstitute: this.attr(
        getDefaultReportValue("consultedDocumentsOfNationalGeographicInstitute")
      ),
      consultedDocumentsOfAira: this.attr(
        getDefaultReportValue("consultedDocumentsOfAira")
      ),
      consultedDocumentsOfDrealDdpp: this.attr(
        getDefaultReportValue("consultedDocumentsOfDrealDdpp")
      ),
      informationTypeOfOthers: this.attr(
        getDefaultReportValue("informationTypeOfOthers")
      ),
      consultedDocumentsOfOthers: this.attr(
        getDefaultReportValue("consultedDocumentsOfOthers")
      ),

      // Information Collected During Interviews (A110)
      informationCollectedDuringInterviews: this.attr(
        getDefaultReportValue("informationCollectedDuringInterviews")
      ),
      // Analysis of old aerial photographs or old plans (A110)
      photographsOrPlans: this.attr(
        getDefaultReportValue("photographsOrPlans")
      ),
      analysisPhotographsOrPlans: this.attr(
        getDefaultReportValue("analysisPhotographsOldPlans")
      ),
      basiasBasolSitesDescription: this.attr(
        getDefaultReportValue("basiasBasolSitesDescription")
      ),
      wasOrNotIndexed: this.attr(getDefaultReportValue("wasOrNotIndexed")),
      inventoryOfIncidents: this.attr(
        getDefaultReportValue("inventoryOfIncidents")
      ),
      activitiesFromNowAndPast: this.attr(
        getDefaultReportValue("activitiesFromNowAndPast")
      ),
      productUsedOnTheSite: this.attr(
        getDefaultReportValue("productUsedOnTheSite")
      ),
      contraintsImposedThroughUsageRestrictions: this.attr(
        getDefaultReportValue("contraintsImposedThroughUsageRestrictions")
      ),
      industrialActivitiesDescription: this.attr(
        getDefaultReportValue("industrialActivitiesDescription")
      ),
      presentationBasiasBasolTitle: this.attr(
        getDefaultReportValue("presentationBasiasBasolTitle")
      ),
      locationBasiasTitle: this.attr(
        getDefaultReportValue("locationBasiasTitle")
      ),
      locationBasiasPlan: this.attr(""),
      riskyActivitesConclusion: this.attr(
        getDefaultReportValue("riskyActivitesConclusion")
      ),
      synthesisHistorical: this.attr(
        getDefaultReportValue("synthesisHistorical")
      ),
      locationActivitiesTitle: this.attr(
        getDefaultReportValue("locationActivitiesTitle")
      ),
      locationActivitiesPlan: this.attr(""),
      photographsPlansAnalysisTitle: this.attr(
        getDefaultReportValue("photographsPlansAnalysisTitle")
      ),
      productUsedAndRawMaterialTitle: this.attr(
        getDefaultReportValue("productUsedAndRawMaterialTitle")
      ),
      siteSourceOfContaminationTitle: this.attr(
        getDefaultReportValue("siteSourceOfContaminationTitle")
      ),
      usedProductAndWasteTitle: this.attr(
        getDefaultReportValue("usedProductAndWasteTitle")
      ),
      usedProductAndWaste: this.attr(
        getDefaultReportValue("usedProductAndWaste")
      ),
      // Vulnerability Study (A120)
      geologicalMapLocation: this.attr(""),
      climateWebsiteLink: this.attr(""),
      geographicLocation: this.attr(
        getDefaultReportValue("geographicLocation")
      ),
      topographicLocation: this.attr(
        getDefaultReportValue("topographicLocation")
      ),
      geologicalMapRegion: this.attr(""),
      siteGeologicalImplantation: this.attr(
        getDefaultReportValue("siteGeologicalImplantation")
      ),
      geologicalMapExtract: this.attr(""),
      nearBssWorksDescription: this.attr(
        getDefaultReportValue("nearBssWorksDescription")
      ),
      bssPointsTitle: this.attr(getDefaultReportValue("bssPointsTitle")),
      bssPointsLocalisation: this.attr(""),
      averageLithologicSection: this.attr(
        getDefaultReportValue("averageLithologicSection")
      ),
      hydrogeologicalContext: this.attr(
        getDefaultReportValue("hydrogeologicalContext")
      ),
      indicatedWaterDepth: this.attr(
        getDefaultReportValue("indicatedWaterDepth")
      ),
      piezometricOrVulnerabilityMap: this.attr(""),
      piezometricOrVulnerabilityMapSource: this.attr(""),
      hydrogeologicalFloodingRisk: this.attr(
        getDefaultReportValue("hydrogeologicalFloodingRisk")
      ),
      locationInRelationToRisingGroundwaterRisk: this.attr(""),
      locationInRelationToRisingGroundwaterRiskSource: this.attr(""),
      hydrogeologicalContextConclusion: this.attr(
        getDefaultReportValue("hydrogeologicalContextConclusion")
      ),
      hydrologicalContext: this.attr(
        getDefaultReportValue("hydrologicalContext")
      ),
      hydrologicContextPresentation: this.attr(""),
      hydrologicContextPresentationSource: this.attr(""),
      hydrologicalFloodingRisk: this.attr(
        getDefaultReportValue("hydrologicalFloodingRisk")
      ),
      locationInRelationToFloodingRisk: this.attr(""),
      locationInRelationToFloodingRiskSource: this.attr(""),
      surfaceWaterVulnerability: this.attr(
        getDefaultReportValue("surfaceWaterVulnerability")
      ),
      onSiteGroundSurfacesDescriptions: this.attr(
        getDefaultReportValue("onSiteGroundSurfacesDescription")
      ),
      offSiteGroundSurfacesDescriptions: this.attr(
        getDefaultReportValue("offSiteGroundSurfacesDescription")
      ),
      climateDescription: this.attr(
        getDefaultReportValue("climateDescription")
      ),
      meteorologicalData: this.attr(""),
      climateDetails: this.attr(getDefaultReportValue("climateDetails")),
      windDistribution: this.attr(""),
      windDistributionSource: this.attr(""),
      landUse: this.attr(getDefaultReportValue("landUse")),
      pluExtract: this.attr(""),
      pluExtractSource: this.attr(""),
      groundwaterUses: this.attr(getDefaultReportValue("groundwaterUses")),
      locationOfGroundwaterCatchmentPoints: this.attr(""),
      locationOfGroundwaterCatchmentPointsSource: this.attr(""),
      sensitiveGroundwaterUses: this.attr(
        getDefaultReportValue("sensitiveGroundwaterUses")
      ),
      mappingOfSensitiveWaterCatchments: this.attr(""),
      mappingOfSensitiveWaterCatchmentsSource: this.attr(""),
      groundwaterUsesConclusion: this.attr(
        getDefaultReportValue("groundWaterUsesConclusion")
      ),
      surfaceWaterUses: this.attr(getDefaultReportValue("surfaceWaterUses")),
      mappingOfSurfaceWaterCatchmentsPoints: this.attr(""),
      mappingOfSurfaceWaterCatchmentsPointsSource: this.attr(""),
      surfaceWaterUsesConclusion: this.attr(
        getDefaultReportValue("surfaceWaterUsesConclusion")
      ),
      protectedAreas: this.attr(getDefaultReportValue("protectedAreas")),
      locationOfProtectedAreas: this.attr(""),
      locationOfProtectedAreasSource: this.attr(""),
      protectedAreasConclusion: this.attr(
        getDefaultReportValue("protectedAreasConclusion")
      ),
      monitoringStructuresIdentification: this.attr(
        getDefaultReportValue("monitoringStructuresIdentification")
      ),
      localisationOfMonitoringStructures: this.attr(""),
      localisationOfMonitoringStructuresSource: this.attr(""),
      exposureRoutesIdentification: this.attr(
        getDefaultReportValue("exposureRoutesIdentification")
      ),
      exposureRoutesZones: this.attr([]),
      groundAndLandToExcavateOnSiteUses: this.attr(
        getDefaultReportValue("groundAndLandToExcavateOnSiteUses")
      ),
      groundAndLandToExcavateOffSiteUses: this.attr(
        getDefaultReportValue("groundAndLandToExcavateOffSiteUses")
      ),
      groundAndLandToExcavateEnvironmentToRemember: this.attr(
        getDefaultReportValue("groundAndLandToExcavateEnvironmentToRemember")
      ),
      undergroundWaterOnSiteUses: this.attr(
        getDefaultReportValue("undergroundWaterOnSiteUses")
      ),
      undergroundWaterOffSiteUses: this.attr(
        getDefaultReportValue("undergroundWaterOffSiteUses")
      ),
      undergroundWaterEnvironmentToRemember: this.attr(
        getDefaultReportValue("undergroundWaterEnvironmentToRemember")
      ),
      superficialWaterOnSiteUses: this.attr(
        getDefaultReportValue("superficialWaterOnSiteUses")
      ),
      superficialWaterOffSiteUses: this.attr(
        getDefaultReportValue("superficialWaterOffSiteUses")
      ),
      superficialWaterEnvironmentToRemember: this.attr(
        getDefaultReportValue("superficialWaterEnvironmentToRemember")
      ),
      groundGasesAmbiantAirSedimentOnSiteUses: this.attr(
        getDefaultReportValue("groundGasesAmbiantAirSedimentOnSiteUses")
      ),
      groundGasesAmbiantAirSedimentOffSiteUses: this.attr(
        getDefaultReportValue("groundGasesAmbiantAirSedimentOffSiteUses")
      ),
      groundGasesAmbiantAirSedimentEnvironmentToRemember: this.attr(
        getDefaultReportValue(
          "groundGasesAmbiantAirSedimentEnvironmentToRemember"
        )
      ),
      foodstuffsTapWaterOnSiteUses: this.attr(
        getDefaultReportValue("foodstuffsTapWaterOnSiteUses")
      ),
      foodstuffsTapWaterOffSiteUses: this.attr(
        getDefaultReportValue("foodstuffsTapWaterOffSiteUses")
      ),
      foodstuffsTapWaterEnvironmentToRemember: this.attr(
        getDefaultReportValue("foodstuffsTapWaterEnvironmentToRemember")
      ),
      // DIAG global mission chapters (no mission code)
      provisionalProgramInvestigationPlan: this.attr(""),
      worksDeclarationType: this.attr(""),
      worksDeclarationNumber: this.attr(""),
      worksDeclarationDate: this.attr(""),
      investigationsAdaptations: this.attr(
        getDefaultReportValue("investigationsAdaptations")
      ),

      // Investigation Strategy (A200)
      soilLaboratoryModification: this.attr(false),
      soilCollectionModification: this.attr(false),
      soilStrategyModification: this.attr(false),
      soilNumberOfSurveys: this.attr(0),
      soilSurveyMaxDepth: this.attr(0),
      soilMeasurements: this.attr(getDefaultReportValue("soilMeasurements")),
      strategySamples: this.attr(getDefaultReportValue("strategySamples")),
      soilInvestigationPlan: this.attr(""),
      soilInvestigationPlansTitle: this.attr(
        getDefaultReportValue("soilInvestigationPlansTitle")
      ),
      postSurveyTreatment: this.attr(
        getDefaultReportValue("postSurveyTreatment")
      ),
      soilLaboratoryAnalysisTreatment: this.attr(
        getDefaultReportValue("soilLaboratoryAnalysisTreatment")
      ),
      soilAnalysisProgramTitle: this.attr(
        getDefaultReportValue("soilAnalysisProgramTitle")
      ),
      laboratoryAnalysisComment: this.attr(""),
      soilConditioning: this.attr(getDefaultReportValue("soilConditioning")),
      //Investigation Strategy (A210)
      a210InstalledPiezometers: this.attr(true),
      worksImplantation: this.attr(getDefaultReportValue("worksImplantation")),
      undergroundWaterNumberOfPiezometers: this.attr(0),
      undergroundWaterPiezometerMaxDepth: this.attr(0),
      undergroundWaterInvestigationPlan: this.attr(""),
      usedMaterial: this.attr(getDefaultReportValue("usedMaterial")),
      cuttingStatus: this.attr(getDefaultReportValue("cuttingStatus")),
      undergroundWaterSamplesCollection: this.attr(
        getDefaultReportValue("undergroundWaterSamplesCollection")
      ),
      undergroundWaterFieldMeasurements: this.attr(
        getDefaultReportValue("undergroundWaterFieldMeasurements")
      ),
      undergroundWaterInvestigationsStructureTitle: this.attr(
        getDefaultReportValue("undergroundWaterInvestigationsStructureTitle")
      ),
      undergroundWaterAnalysisDoneBy: this.attr(""),
      undergroundWaterAnalyticalProgramTitle: this.attr(
        getDefaultReportValue("undergroundWaterAnalyticalProgramTitle")
      ),
      undergroundWaterLaboratoryModification: this.attr(false),
      undergroundWaterCollectionModification: this.attr(false),
      undergroundWaterStrategyModification: this.attr(false),
      undergroundWaterConditioning: this.attr(
        getDefaultReportValue("undergroundWaterConditioning")
      ),
      // Superficial water samples collection (A220)
      superficialWaterCollectionModification: this.attr(false),
      superficialWaterStrategyModification: this.attr(false),
      superficialWaterLaboratoryModification: this.attr(false),
      superficialWaterSamplesCollection: this.attr(
        getDefaultReportValue("superficialWaterSamplesCollection")
      ),
      superficialWaterOrSedimentInvestigationPlan: this.attr(""),
      superficialWaterOrSedimentTitle: this.attr(
        getDefaultReportValue("superficialWaterOrSedimentTitle")
      ),
      superficialWaterInvestigationMaterial: this.attr(
        getDefaultReportValue("superficialWaterInvestigationMaterial")
      ),
      superficialWaterConditioning: this.attr(
        getDefaultReportValue("superficialWaterConditioning")
      ),
      superficialWaterMeasurements: this.attr(
        getDefaultReportValue("superficialWaterMeasurements")
      ),
      superficialWaterLaboratory: this.attr(
        getDefaultReportValue("superficialWaterLaboratory")
      ),
      superficialWaterLaboratoryAnalysisTitle: this.attr(
        getDefaultReportValue("superficialWaterLaboratoryAnalysisTitle")
      ),
      superficialWaterAnnexAnalysisModality: this.attr(
        getDefaultReportValue("superficialWaterAnnexAnalysisModality")
      ),
      //Weather conditions before and during the sampling field (A230)
      a230InstalledPiezometers: this.attr(true),
      weatherStationLocation: this.attr(
        getDefaultReportValue("weatherStationLocation")
      ),
      weatherFromPreviousDaysChoice: this.attr("TABLE"),
      weatherFromPreviousDaysDoc: this.attr(""),
      weatherSource: this.attr(getDefaultReportValue("weatherSource")),
      monthOfWeatherData: this.attr(""),
      weatherConditionsMonthAndPlace: this.attr(""),
      weatherConditionsSource: this.attr(""),
      weatherConditionsGraphic: this.attr(""),
      weatherSampleCampaignContext: this.attr(
        getDefaultReportValue("weatherSampleCampaignContext")
      ),
      weatherConditionsAppreciation: this.attr(""),
      weatherResultsAppreciation: this.attr(""),
      //Investigation strategy - Installation of strutures (A230)
      soilGasesStrategyModification: this.attr(false),
      soilGasesLaboratoryModification: this.attr(false),
      soilGasesCollectionModification: this.attr(false),
      fieldInvestigationsDescription: this.attr(
        getDefaultReportValue("fieldInvestigationsDescription")
      ),
      fieldInvestigationsEquipment: this.attr(
        getDefaultReportValue("fieldInvestigationsEquipment")
      ),
      soilGasInvestigationPlan: this.attr(""),
      piezairsInstallationDescription: this.attr(
        getDefaultReportValue("piezairsInstallationDescription")
      ),
      cuttingsInstallationDescription: this.attr(
        getDefaultReportValue("cuttingsInstallationDescription")
      ),
      gasCaneCapillaryInstallationUninstallation: this.attr(
        getDefaultReportValue("gasCaneCapillaryInstallationUninstallation")
      ),
      //Field Measurements And Observations (A230)
      piezairSectionDescription: this.attr(
        getDefaultReportValue("piezairSectionDescription")
      ),
      piezairSectionObservations: this.attr(
        getDefaultReportValue("piezairSectionObservations")
      ),
      //Sample Collection And Packaging (A230)
      numberOfSoilGasSample: this.attr(""),
      soilGasSupport: this.attr(getDefaultReportValue("soilGasSupport")),
      soilGasCompositePlace: this.attr(
        getDefaultReportValue("soilGasCompositePlace")
      ),
      methodologyOfSoilGasSampling: this.attr(
        getDefaultReportValue("methodologyOfSoilGasSampling")
      ),
      supportUsedForSoilGasSampling: this.attr(
        getDefaultReportValue("supportUsedForSoilGasSampling")
      ),
      debitOfSoilGasSampling: this.attr(""),
      timeOfSoilGasSampling: this.attr(""),
      volumeOfSoilGasSampling: this.attr(""),
      whiteTransportAndField: this.attr(
        getDefaultReportValue("whiteTransportAndField")
      ),
      soilGasesSamplingTimesAndFlowRatesTitle: this.attr(
        getDefaultReportValue("soilGasesSamplingTimesAndFlowRatesTitle")
      ),
      soilGasesSamplingTimesAndFlowRatesDoc: this.attr(""),
      soilGasInvestigationTitle: this.attr(
        getDefaultReportValue("soilGasInvestigationTitle")
      ),
      weatherFromPreviousDaysTitle: this.attr(
        getDefaultReportValue("weatherFromPreviousDaysTitle")
      ),
      //Laboratory Analyzes (A230)
      soilGasAnnexAnalyseModality: this.attr(
        getDefaultReportValue("soilGasAnnexAnalyseModality")
      ),
      //Investigation Strategy (A240)
      airWeatherFromPreviousDaysChoice: this.attr("TABLE"),
      airWeatherFromPreviousDaysDoc: this.attr(""),
      ambientAirMeteorologicalStation: this.attr(""),
      ambientAirMeteorologicalParametersPeriod: this.attr(""),
      ambientAirMeteorologicalConditionsGraph: this.attr(""),
      ambientAirSamplingCampaignContext: this.attr(
        getDefaultReportValue("ambientAirSamplingCampaignContext")
      ),
      ambientAirMeteorologicalConditions: this.attr(""),
      ambientAirResults: this.attr(""),
      samplingType: this.attr(""),
      supportDescription: this.attr(
        getDefaultReportValue("supportDescription")
      ),
      ambiantAirConditioning: this.attr(
        getDefaultReportValue("ambiantAirConditioning")
      ),
      ambiantAirStrategyModification: this.attr(false),
      ambiantAirCollectionModification: this.attr(false),
      ambiantAirLaboratoryModification: this.attr(false),
      sampleLocationDefinition: this.attr(
        getDefaultReportValue("sampleLocationDefinition")
      ),
      ambientAirInvestigationPlan: this.attr(""),
      sampleHoldersNumber: this.attr(""),
      sampleHoldersHeight: this.attr(""),
      samplingPointsPhotos: this.attr([]),
      ambientAirAnalysisDoneBy: this.attr(""),
      airAmbiantSamplingTimesAndFlowRatesDoc: this.attr(""),
      ambientAirSamplingJustificationTitle: this.attr(
        getDefaultReportValue("ambientAirSamplingJustificationTitle")
      ),
      ambientAirAnalysisProgramTitle: this.attr(
        getDefaultReportValue("ambientAirAnalysisProgramTitle")
      ),
      airWeatherFromPreviousDaysTitle: this.attr(
        getDefaultReportValue("airWeatherFromPreviousDaysTitle")
      ),
      //Investigation Strategy (A250)
      presentContaminants: this.attr(
        getDefaultReportValue("presentContaminants")
      ),
      perimiterDelimitation: this.attr(
        getDefaultReportValue("perimiterDelimitation")
      ),
      historicalElements: this.attr(
        getDefaultReportValue("historicalElements")
      ),
      gardenInformations: this.attr(
        getDefaultReportValue("gardenInformations")
      ),
      environmentalStudy: this.attr(
        getDefaultReportValue("environmentalStudy")
      ),
      investigationStrategySample: this.attr(""),
      qgisPlan: this.attr(""),
      measurementsAndObservations: this.attr(""),
      annexSampleModality: this.attr(""),
      //Laboratory Analyzes (A250)
      annexAnalyseModality: this.attr(
        getDefaultReportValue("annexAnalyseModality")
      ),
      foodLaboratoryAnalysisTitle: this.attr(
        getDefaultReportValue("annexAnalyseModality")
      ),
      foodSampleEnvironmentTitle: this.attr(
        getDefaultReportValue("foodSampleEnvironmentTitle")
      ),
      foodLaboratoryModification: this.attr(false),
      foodCollectionModification: this.attr(false),
      foodStrategyModification: this.attr(false),
      //Investigation Strategy (A260)
      excavatedGroundStrategyModification: this.attr(false),
      excavatedGroundLaboratoryModification: this.attr(false),
      excavatedGroundCollectionModification: this.attr(false),
      excavatedGroundDrillingDetails: this.attr(
        getDefaultReportValue("excavatedGroundDrillingDetails")
      ),
      excavatedGroundStrategySamples: this.attr(
        getDefaultReportValue("excavatedGroundStrategySamples")
      ),
      excavatedGroundInvestigationPlan: this.attr(""),
      removingExcavatedGroundProtocol: this.attr(
        getDefaultReportValue("removingExcavatedGroundProtocol")
      ),
      postSamplingTreatment: this.attr(
        getDefaultReportValue("postSamplingTreatment")
      ),
      excavatedGroundSamplesDetails: this.attr(
        getDefaultReportValue("excavatedGroundSamplesDetails")
      ),
      excavatedGroundObservations: this.attr(""),
      excavatedGroundConditioning: this.attr(
        getDefaultReportValue("excavatedGroundConditioning")
      ),
      excavatedGroundAnalysisDoneBy: this.attr(
        getDefaultReportValue("excavatedGroundAnalysisDoneBy")
      ),
      waterOrigin: this.attr(getDefaultReportValue("waterOrigin")),
      excavatedGroundAnalysisProgramTitle: this.attr(
        getDefaultReportValue("excavatedGroundAnalysisProgramTitlethis")
      ),
      //Observations and field measurements (A270)
      interpretationIntroduction: this.attr(
        getDefaultReportValue("interpretationIntroduction")
      ),
      soilObservations: this.attr(getDefaultReportValue("soilObservations")),
      excavatedGroundObservationsMeasurements: this.attr(
        getDefaultReportValue("excavatedGroundObservationsMeasurements")
      ),
      groundwaterObservations: this.attr(
        getDefaultReportValue("groundwaterObservations")
      ),
      piezometricLevelsMeasurements: this.attr(
        getDefaultReportValue("piezometricLevelsMeasurements")
      ),
      piezometricMap: this.attr(""),
      superficialWaterObservations: this.attr(
        getDefaultReportValue("superficialWaterObservations")
      ),
      soilGasesObservations: this.attr(
        getDefaultReportValue("soilGasesObservations")
      ),
      soilGasesObservationsDoc: this.attr(""),
      ambiantAirObservations: this.attr(
        getDefaultReportValue("ambiantAirObservations")
      ),
      ambiantAirObservationsDoc: this.attr(""),
      tapWaterFoodstuffObservations: this.attr(
        getDefaultReportValue("tapWaterFoodstuffObservations")
      ),
      tapWaterFoodstuffObservationsDoc: this.attr(""),
      // Reference values soils Related field (A270)
      soilReferenceValues: this.attr(
        getDefaultReportValue("soilReferenceValues")
      ),
      // Groundwater reference values (A270)
      prefecturalDecree: this.attr(getDefaultReportValue("prefecturalDecree")),
      // Reference values surface waters and/or sediments (A270)
      surfaceWatersSedimentsTitleChoice: this.attr(
        getDefaultReportValue("surfaceWatersSedimentsTitleChoice")
      ),

      // Reference values soil gases / ambient air (A270)
      actionAccordingToThresholds: this.attr(
        getDefaultReportValue("actionAccordingToThresholds")
      ),
      // Reference values dust (A270)
      dustComparisonOfResults: this.attr(
        getDefaultReportValue("dustComparisonOfResults")
      ),
      // Reference values foodstuffs / tap water (A270)
      comparisonOfResults: this.attr(
        getDefaultReportValue("comparisonOfResults")
      ),
      // Reference values excavated soil (A270)
      excavatedSoilReference: this.attr(
        getDefaultReportValue("excavatedSoilReference")
      ),
      analysisResultsDocsTitle: this.attr(
        getDefaultReportValue("analysisResultsDocsTitle")
      ),
      analysisResultsDocs: this.attr([]),
      //Analysis Interpretation (A270)
      analysisInterpretationIntroduction: this.attr(
        getDefaultReportValue("analysisInterpretationIntroduction")
      ),
      soilAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      undergroundWaterAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      surfaceWaterAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      soilGasesSamplesValidity: this.attr(
        getDefaultReportValue("samplesValidity")
      ),
      soilGasesAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      ambiantAirSamplesValidity: this.attr(
        getDefaultReportValue("samplesValidity")
      ),
      ambiantAirAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      foodstuffAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      excavatedGroundAnalysisInterpretation: this.attr(
        getDefaultReportValue("analysisInterpretation")
      ),
      analysisInterpretationSynthesisResults: this.attr(""),
      interpretationAnalyzesDocs: this.attr([]),
      //Concept Map update (A270)
      conceptMapUpdateIntroduction: this.attr(
        getDefaultReportValue("conceptMapUpdateIntroduction")
      ),
      // Assumptions considered (A270)
      assumptionsConsidered: this.attr(
        getDefaultReportValue("assumptionsConsidered")
      ),
      // Identification of sources of contamination (A270)
      sourcesOfContamination: this.attr(
        getDefaultReportValue("sourcesOfContamination")
      ),
      //Identification of human targets (A270)
      humanTargetIdentification: this.attr(
        getDefaultReportValue("humanTargetIdentification")
      ),
      // Identification of exposure media and their uses (A270)
      exposureMediaAndTheirUses: this.attr(
        getDefaultReportValue("exposureMediaAndTheirUses")
      ),
      // Identification of transfer pathways (A270)
      soilContaminantTransfer: this.attr(
        getDefaultReportValue("soilContaminantTransfer")
      ),
      undergroundWatersContaminantTransfer: this.attr(
        getDefaultReportValue("undergroundWatersContaminantTransfer")
      ),
      contaminantNotTransfer: this.attr(
        getDefaultReportValue("contaminantNotTransfer")
      ),
      transferRoutes: this.attr(getDefaultReportValue("transferRoutes")),
      offsiteTransfer: this.attr(getDefaultReportValue("offsiteTransfer")),
      conceptualDiagramDocument: this.attr(""),

      piezometricLevelTableTitle: this.attr(
        getDefaultReportValue("piezometricLevelTableTitle")
      ),
      filteredWaterCompositionsTitle: this.attr(
        getDefaultReportValue("filteredWaterCompositionsTitle")
      ),
      filteredGroundWaterCompositionsTitle: this.attr(
        getDefaultReportValue("filteredGroundWaterCompositionsTitle")
      ),

      // Annexes
      undergroundWaterSamplingSheet: this.attr(""),
      undergroundWaterPiezoCut: this.attr(""),
      superficialWaterSamplingSheet: this.attr(""),
      sedimentSamplingSheet: this.attr(""),
      soilGasesSamplingSheet: this.attr(""),
      soilGasesPiezairCut: this.attr(""),
      ambiantAirQuestionnaire: this.attr(""),
      ambiantAirSamplingSheet: this.attr(""),
      atmosphericDustSamplingSheet: this.attr(""),
      foodTapWaterSamplingSheet: this.attr(""),
      excavatedGroundSampleCut: this.attr(""),
      soilAnalysisAnnexSchedule: this.attr(""),
      waterAnalysisAnnexSchedule: this.attr(""),
      soilGasesAnalysisAnnexSchedule: this.attr(""),
      foodstuffAnalysisAnnexSchedule: this.attr(""),
      sedimentAnalysisAnnexSchedule: this.attr(""),
      airAnalysisAnnexSchedule: this.attr(""),
      additionalAnnexDocs: this.attr([]),

      // Non Technical Summary
      siteVisitSuperficyOccupiedBy: this.attr(
        getDefaultReportValue("siteVisitSuperficyOccupiedBy")
      ),
      presenceOfPastActivity: this.attr(""),
      sourceOfContaminationIdentification: this.attr(""),
      presenceOfAnthropogenic: this.attr(
        getDefaultReportValue("presenceOfAnthropogenic")
      ),
      undergroundWaterVulnerability: this.attr(""),
      natureOfTheAquifer: this.attr(
        getDefaultReportValue("natureOfTheAquifer")
      ),
      undergroundWaterSensibility: this.attr(""),
      undergroundWaterPresence: this.attr(""),
      superficialWaterVulnerability: this.attr(""),
      superficialWaterProximity: this.attr(""),
      superficialWaterSensibility: this.attr(""),
      superficialWaterActivities: this.attr(""),
      environmentVulnerability: this.attr(""),
      presenceOfInstitution: this.attr(
        getDefaultReportValue("presenceOfInstitution")
      ),
      recommandationChoice: this.attr(""),
      nonTechnicalSummaryRecommandation: this.attr(""),
      nonTechnicalSummaryConclusion: this.attr(
        getDefaultReportValue("nonTechnicalSummaryConclusion")
      ),

      // Technical Summary
      classificationForIcpe: this.attr(""),
      technicalSummaryStudyContext: this.attr(""),
      technicalSummarySiteFutureUse: this.attr(""),
      technicalSummaryPreviousStudyAvailable: this.attr(""),
      technicalSummaryReferenceOfStudy: this.attr(""),
      technicalSummaryMethodologyPollutedSoils: this.attr(""),
      siteHistory: this.attr(""),
      knownPriorPollution: this.attr(""),
      knownEnvironmentalAccident: this.attr(""),
      presenceOfBackfill: this.attr(""),
      backfillEstimatedDepth: this.attr(""),
      technicalSummarySecurityMeasure: this.attr(""),
      technicalSummaryGeology: this.attr(""),
      technicalSummaryHydrology: this.attr(""),
      technicalSummaryHydrogeology: this.attr(""),
      technicalSummarySoilVulnerability: this.attr(""),
      technicalSummaryUndergroundWaterVulnerability: this.attr(""),
      technicalSummarySuperficialWaterVulnerability: this.attr(""),
      technicalSummaryEnvironmentVulnerability: this.attr(""),
      technicalSummaryTargets: this.attr(""),
      technicalSummaryExposurePathways: this.attr(""),
      technicalSummaryTransferPath: this.attr(""),
      lithologicalSection: this.attr(""),
      technicalSummaryInvestigationPlanned: this.attr(
        getDefaultReportValue("technicalSummaryInvestigationsPlanned")
      ),
      technicalSummaryChangesToTheMission: this.attr(""),
      technicalSummaryResultsInterpretation: this.attr(
        getDefaultReportValue("technicalSummaryresultsInterpretation")
      ),
      technicalSummaryConclusions: this.attr(""),
      hasTechnicalSummaryRecommandations: this.attr(""),
      technicalSummaryRecommandations: this.attr(
        getDefaultReportValue("technicalSummaryRecommandations")
      ),
      conclusion: this.attr(getDefaultReportValue("conclusion")),
      recommendations: this.attr(getDefaultReportValue("recommendations")),
      // Assesment of Uncertainties
      assessmentOfUncertaintiesIntroduction: this.attr(
        getDefaultReportValue("assessmentOfUncertaintiesIntroduction")
      ),
      meansUsedSiteVisite: this.attr(
        getDefaultReportValue("meansUsedSiteVisite")
      ),
      meansUsedHistoricalStudy: this.attr(
        getDefaultReportValue("meansUsedHistoricalStudy")
      ),
      meansUsedSurveyImplementation: this.attr(
        getDefaultReportValue("meansUsedSurveyImplementation")
      ),
      meansUsedPackaging: this.attr(
        getDefaultReportValue("meansUsedPackaging")
      ),
      meansUsedAnalyticalMethods: this.attr(
        getDefaultReportValue("meansUsedAnalyticalMethods")
      ),
      meansUsedAnalyticalProgramme: this.attr(
        getDefaultReportValue("meansUsedAnalyticalProgramme")
      ),
      meansUsedConceptualDiagram: this.attr(
        getDefaultReportValue("meansUsedConceptualDiagram")
      ),
    };
  }
}
