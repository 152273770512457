import { Model } from "@vuex-orm/core";
import Analysis from "@/models/Analysis";

export default class ComplementaryAnalysis extends Model {
  static entity = "complementaryAnalysis";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      complementaryAnalysisRequest: this.string(),
      sample: this.string(),
      analysis: this.attr([]),
      sentStatus: this.string(),
      analysisData: this.hasManyBy(Analysis, "analysis"),
    };
  }
}
