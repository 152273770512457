import BasePurge from "@/models/Purge/BasePurge";
import PurgeUndergroundWatersMeasure from "@/models/Purge/PurgeUndergroundWatersMeasure";

export default class PurgeUndergroundWaters extends BasePurge {
  static entity = "purgeUndergroundWaters";

  static fields() {
    return {
      ...super.fields(),
      toolTotalDepth: this.number(),
      waterDepthFromMarker: this.number(),
      watersManagement: this.attr([]),
      purgeDepth: this.number(),
      hasSupernatant: this.boolean(),
      supernatantControl: this.string(),
      pumpDepth: this.number(),
      type: this.string(),
      piezometerLevelAfterPurge: this.number(),
      waterLevelDepthFromSoil: this.number(),
      toolTotalDepthFromSoil: this.number(),
      measures: this.hasMany(PurgeUndergroundWatersMeasure, "purge"),
    };
  }
}
