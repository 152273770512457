import { Model } from "@vuex-orm/core";
import { COMPANY_TYPE_CHOICES } from "@/constants/companyType";

export default class AdditionalMaterial extends Model {
  static entity = "additionalMaterial";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      name: this.attr(),
      company: this.attr(),
      companyType: this.attr(COMPANY_TYPE_CHOICES.SUBCONTRACTOR.value),
      materialType: this.attr(),
      isValidated: this.boolean(false),
      preparation: this.attr(null),
    };
  }
  get designation() {
    return this.name;
  }
  get typologies() {
    return [];
  }
}
