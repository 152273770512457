import { Model } from "@vuex-orm/core";

export default class AdditionalInformation extends Model {
  static entity = "additionalInformation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(""),
      laboratoryName: this.attr("EUROFINS"),
      samplesSendingDate: this.attr(""),
      transportCondition: this.attr(""),
      bottlingType: this.attr(""),
      sampler: this.attr(""),
      temperature: this.attr(""),
      weatherCondition: this.attr(""),
    };
  }
}
