import { Model } from "@vuex-orm/core";

export default class Questionnaire extends Model {
  static entity = "questionnaire";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Questionnaire";

  static fields() {
    return {
      uuid: this.uid(),
      project: this.attr(""),
      // Preliminary informations
      authorName: this.attr(""),
      agency: this.attr(""),
      visitDate: this.attr(""), // Date
      dateOfAdditionalVisit: this.attr(""), // Date
      contextList: this.attr([]), // Array<String>
      contextComment: this.attr(""),
      siteDocuments: this.attr(""),
      // Description
      typologyAndUsageList: this.attr([]), // Array<String>
      typologyAndUsageComment: this.attr(""),
      accessCondition: this.attr(""),
      attendingPopulation: this.attr(""), // String (Attendance)
      numberOfAttendingPopulation: this.attr(null), /// Integer
      attendingPopulationsTypologyList: this.attr([]), // choices: PopulationTypology
      // Claims
      claimsExisting: this.attr(null), // Boolean
      claimsConcernAreasList: this.attr([]), // Array<String>
      // Other caracteristics
      presencesOfList: this.attr([]), // Array<String>
      presencesOfRelatedRisks: this.attr(""),
      // Site environ
      hasAgriculturalForestry: this.attr(null), // Boolean
      hasProtectedArea: this.attr(null), // Boolean
      hasIndustrial: this.attr(null), // Boolean
      hasCommercial: this.attr(null), // Boolean
      hasSensitiveEstablishments: this.attr(null), // Boolean
      hasHousing: this.attr(null), // Boolean
      housingTypeList: this.attr([]), // Array<String>
      crawlSpace: this.attr(""),
      layoutDiagramUuid: this.attr(""), //UUID
      // Industrial activity
      industrialActivity: this.attr(""),

      // Ambient air
      hasContaminantVolatilePowdery: this.attr(null), // Boolean
      natureOfContaminantVolatilePowdery: this.attr(""),
      hasContaminantGaseousOrDusty: this.attr(null), // Boolean
      natureOfContaminantGaseousOrDusty: this.attr(""),
      // Superficial water
      nearestWatercourseDistance: this.attr(null), // Float
      watercourseFlowsEstimate: this.attr(null), // Float
      existenceOfSensitiveWaterCourse: this.attr(null), // Boolean
      natureOfNearestSensitiveWatercourse: this.attr(""),
      hasContaminantDirectDischarges: this.attr(null), // Boolean
      hasSignsOfSurfaceRunoff: this.attr(null), // Boolean
      presencePonds: this.attr(null), // Boolean
      isPotentialFloodZone: this.attr(null), // Boolean
      // Underground water
      groundwaterTable: this.attr(""), // String (ClosedAnswer)
      aquiferType: this.attr(""),
      estimatedDepthGroundwater: this.attr(null), // Float
      hasSensitiveUsageOfGroundwater: this.attr(null), // Boolean
      natureOfSensitiveUsageOfGroundwater: this.attr(""),
      distanceOfNearestWaterCatchment: this.attr(null), // Float
      hasPreferentialCirculationTowardsTheWaterTable: this.attr(null), // Boolean
      preferentialCirculationTowardsTheWaterTableType: this.attr(""),
      hasCoverByLowPermeabilityGeologicalFormations: this.attr(null), // Boolean
      coverByLowPermeabilityGeologicalFormationsNature: this.attr(""),
      // Ground
      indicationsSoilPollutionOutsideTheSite: this.attr(null), // Boolean
      indicationsSoilPollutionOnTheSite: this.attr(null), // Boolean
      // Conceptual schema
      znsNature: this.attr(""),
      watertableThickness: this.attr(""),
      surfaceWaterRelationship: this.attr(""),
      undergroundWaterUsage: this.attr(""),
      surfaceWaterDrainage: this.attr(""),
      runoffFromSource: this.attr(""),
      waterflow: this.attr(""),
      surfaceWaterUsage: this.attr(""),
      peopleUsingSite: this.attr(""),
      contaminantAccessibility: this.attr(""),
      soilUsage: this.attr(""),
      hazardousOrInconvenient: this.attr(""),
      riskOfHasardousSubstancesCarriedByWatertable: this.attr(""),
      confinedSpaceExists: this.attr(""),
      housingOnSiteOrVincinity: this.attr(""),
      // Security measures
      drumsCanistersRemovalEmergencyLevel: this.attr(""),
      landExcavationEmergencyLevel: this.attr(""),
      productsOrSourcesStabilizationEmergencyLevel: this.attr(""),
      containmentImplementationEmergencyLevel: this.attr(""),
      accessToTheSiteRestrictionEmergencyLevel: this.attr(""),
      theSiteEvacuationEmergencyLevel: this.attr(""),
      groundwaterMonitoringNetworkCreationEmergencyLevel: this.attr(""),
      drinkingWaterSupplySourceControlEmergencyLevel: this.attr(""),
      superstructuresDemolitionEmergencyLevel: this.attr(""),
      voidsFillingEmergencyLevel: this.attr(""),
      // Protection measure
      hasEepUsage: this.attr(""), // Boolean
      otherEep: this.attr(""), // Char
      hasCoactivity: this.attr(""), // Boolean
      hasAtexZone: this.attr(""), // Boolean
      hasHealthOrBiologicalRisks: this.attr(""), // Boolean
      hasChemicalRisks: this.attr(""), // Boolean
      hasAsbestosMaterials: this.attr(""), // Boolean
      asbestosMaterialsType: this.attr(""), // Char
      hasStaggeredWorking: this.attr(""), // Boolean
      isDifficultWorkEnvironment: this.attr(""), // Boolean
      difficultWorkEnvironmentType: this.attr([]), // Array
    };
  }
}
