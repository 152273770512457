import { Model } from "@vuex-orm/core";

export default class HistoricalResearch extends Model {
  static entity = "historicalResearch";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      project: this.attr(""),
      label: this.attr(""),
      url: this.attr(""),
      type: this.attr(""),
      referenceFileNumber: this.attr(""),
      year: this.attr(""),
      documentNature: this.attr(""),
      sourceNature: this.attr(""),
      rayon: this.attr(""),
      localisationPlan: this.attr(""),
      observations: this.attr(""),
      isConsulted: this.boolean(false),
      nonConsultingReason: this.attr(""),
    };
  }
}
