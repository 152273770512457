import { Model } from "@vuex-orm/core";

export default class BasePurge extends Model {
  static primaryKey = "uuid";
  static fields() {
    return {
      uuid: this.attr(),
      survey: this.attr(),
      date: this.attr(),
      createdAt: this.attr(),
      materials: this.attr([]),
      flow: this.number(),
      startDatetime: this.attr(),
      endDatetime: this.attr(),
      isValidityForced: this.boolean(),
      notes: this.attr(),
      duration: this.number(),
      estimatedQuantity: this.number(),
      effectiveQuantity: this.number(),
      estimatedDuration: this.number(),
      valid: this.boolean(),
    };
  }
}
