import { Model } from "@vuex-orm/core";

export default class Building extends Model {
  static entity = "building";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Building";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      denomination: this.attr(""),
      size: this.attr(""),
      buildingTypeList: this.attr([]), // Array<String>
      state: this.attr(""), // String
      comments: this.attr(""), // String
      numberOfFloor: this.attr(""),
      hasBasements: this.attr(null), // Boolean
      basementsTypeList: this.attr([]), // Array<String>
      hasBoilerRoom: this.attr(null), // Boolean
      hasBoilerRoomTankType: this.attr(false), // Boolean
      boilerRoomTypeOfFuel: this.attr(""),
      boilerRoomTankTypeList: this.attr([]), // Array<String>
      boilerRoomTankVolume: this.attr(""),
      containmentSealingUndergroundTypeList: this.attr([]), // Array<String>
      hasContainmentSealingUnderground: this.attr(null), // Boolean
      hasTransformerRoom: this.attr(null), // Boolean
      transformerRoomDielectricTypeList: this.attr([]), // Array<String>
      doesTransformerRoomRetentionExists: this.attr(null), // Boolean
      hasAbandonedWaste: this.attr(null), // Boolean
      abandonedWasteNature: this.attr(""), //String
      hasCoolingUnits: this.attr(null), // Boolean
      coolingUnitsNumber: this.attr(null), // Integer
      coolingUnitsLocation: this.attr(""),
      hasGeneratorRoom: this.attr(null), // Boolean
      generatorRoomLocation: this.attr(""),
      generatorRoomTypeOfFuel: this.attr(""),
      hasLocalCompressor: this.attr(null), // Boolean
      localCompressorLocation: this.attr(""),
      localCompressorTypeOfPowerSupply: this.attr(""),
      hasTraceOfPollution: this.attr(null), // Boolean
      traceOfPollutionLocation: this.attr(""),
      traceOfPollutionType: this.attr(""),
      isAsbestosSuspected: this.attr(null), // Boolean
      asbestosNatureList: this.attr([]), // Array<String>
      isUsed: this.attr(null), // Boolean
      usageType: this.attr(""),
      isAccessibleToPublic: this.attr(null), // Boolean
    };
  }
}
