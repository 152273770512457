import { Model } from "@vuex-orm/core";

export default class SuperStructure extends Model {
  static entity = "supersructure";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SuperStructure";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      hasLoadingUnloadingStation: this.attr(null), // Boolean
      loadingUnloadingStationUsage: this.attr(""),
      hasLoadingDock: this.attr(null), // Boolean
      loadingDockUsage: this.attr(""),
      hasTransformerRoom: this.attr(null), // Boolean
      transformerRoomDielectricNature: this.attr(""),
      hasUnloadingFacility: this.attr(null), // Boolean
      unloadingFacilityUsage: this.attr(""),
      hasFuelDistribution: this.attr(null), // Boolean
      fuelDistributionUsage: this.attr(""),
      fuelDistributionNature: this.attr(""),
      hasElectricityNetwork: this.attr(null), // Boolean
      electricityNetworkLevel: this.attr(""), // String (Level)
      hasTelecomNetwork: this.attr(null), // Boolean
      telecomNetworkLevel: this.attr(""), // String (Level)
      hasExternalLightingNetwork: this.attr(""),
      externalLightingNetworkLevel: this.attr(""), // String (Level)
      hasAepNetwork: this.attr(null), // Boolean
      aepNetworkLevel: this.attr(""), // String (Level)
      hasGasNetwork: this.attr(null), // Boolean
      gasNetworkLevel: this.attr(""), // String (Level)
      hasEuNetwork: this.attr(null), // Boolean
      hasEpNetwork: this.attr(null), // Boolean
      hasWaterTreatmentPlant: this.attr(null), // Boolean
      waterTreatmentPlantLocation: this.attr(""),
      hasSeparatorSludgeTrap: this.attr(null), // Boolean
      separatorSludgeTrapNumber: this.attr(null), // Integer
      separatorSludgeTrapLocation: this.attr(""),
      hasGeneratorRoom: this.attr(null), // Boolean
      generatorRoomLocation: this.attr(""),
      generatorRoomTypeOfFuel: this.attr(""),
      hasCompressorRoom: this.attr(null), // Boolean
      compressorRoomLocation: this.attr(""),
      compressorRoomTypeOfPowerSupply: this.attr(""),
      hasBoilerRoom: this.attr(null), // Boolean
      boilerRoomTypeList: this.attr([]), // Array<String>
      boilerRoomTankLevel: this.attr(""), // String (Level)
      boilerRoomTankVolume: this.attr(null), // Float
      comments: this.attr(""),
    };
  }
}
