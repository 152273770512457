import { Model } from "@vuex-orm/core";
import getDefaultReportValue from "@/composables/questionnaire/tree-form/report/getDefaultReportValue";

export default class ProvisionalProgram extends Model {
  static entity = "provisionalProgram";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      questionnaire: this.attr(""),
      comment: this.attr(""),
      ambiantAirDrillMode: this.attr(""),
      ambiantAirSamplingStandards: this.attr(
        getDefaultReportValue("ambiantAirSamplingStandards")
      ),
      foodstuffsTapWaterDrillMode: this.attr(""),
      foodstuffsTapWaterSamplingStandards: this.attr(
        getDefaultReportValue("foodstuffsTapWaterSamplingStandards")
      ),
      groundAndLandToExcavateDrillMode: this.attr(
        getDefaultReportValue("groundAndLandToExcavateDrillMode")
      ),
      groundAndLandToExcavateSamplingStandards: this.attr(
        getDefaultReportValue("groundAndLandToExcavateSamplingStandards")
      ),
      groundGasesDrillMode: this.attr(
        getDefaultReportValue("groundGasesDrillMode")
      ),
      groundGasesSamplingStandards: this.attr(
        getDefaultReportValue("groundGasesSamplingStandards")
      ),
      sedimentDrillMode: this.attr(""),
      sedimentSamplingStandards: this.attr(
        getDefaultReportValue("sedimentSamplingStandards")
      ),
      superficialWaterDrillMode: this.attr(""),
      superficialWaterSamplingStandards: this.attr(
        getDefaultReportValue("superficialWaterSamplingStandards")
      ),
      undergroundWaterDrillMode: this.attr(
        getDefaultReportValue("undergroundWaterDrillMode")
      ),
      undergroundWaterSamplingStandards: this.attr(
        getDefaultReportValue("undergroundWaterSamplingStandards")
      ),
    };
  }
}
