import { Model } from "@vuex-orm/core";

export default class CalibrationsVerification extends Model {
  static entity = "calibrationsVerification";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(),
      createdAt: this.attr(),
      date: this.attr(""),
      project: this.attr(""),
      calibrationMaterial: this.attr(),
      ambientTemperature: this.attr(),
      ambientTemperatureFirstPoint: this.attr(),
      ambientTemperatureSecondPoint: this.attr(),
      ambientTemperatureConductimetre: this.attr(),
      verificationvalueReadFirstPoint: this.attr(),
      verificationvalueReadSecondPoint: this.attr(),
      verificationvalueReadConductimetre: this.attr(),
      verificationValueMeasuredOutdoors: this.attr(),
      verificationValueMeasuredIsobutylene: this.attr(),
      isobutyleneBottleNumber: this.attr(),
      isFirstCalibrationPointAccessible: this.boolean(false),
      isSecondCalibrationPointAccessible: this.boolean(false),
      isConductimetreAccessible: this.boolean(false),
      calibrationValueReadFirstPoint: this.attr(),
      calibrationValueReadSecondPoint: this.attr(),
      calibrationValueReadConductimetre: this.attr(),
      isOutdoorsAccessible: this.boolean(false),
      calibrationValueReadOutdoors: this.attr(),
      isIsobutyleneAccessible: this.boolean(false),
      calibrationValueReadIsobutylene: this.attr(),
    };
  }
}
