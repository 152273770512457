import { Model } from "@vuex-orm/core";
export default class SiteSourceOfContamination extends Model {
  static entity = "siteSourceOfContamination";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SiteSourceOfContamination";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      source: this.attr(""),
      location: this.attr(""),
      sourceDepth: this.attr(""),
      tracerCompounds: this.attr(""),
      nowOrPast: this.attr(""),
    };
  }
}
