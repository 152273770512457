import store from "@/store";
import getCurrentUser from "@/router/middleware/getCurrentUser";
import SspMobileChildrens from "@/router/mobile/children/ssp-mobile-childrens";

const OidcCallback = () =>
  import(/* webpackChunkName: "OidcCallback" */ "@/components/OidcCallback");
const SspMobile = () =>
  import(/* webpackChunkName: "Ssp" */ "@/components/SspMobile");

export const mobileRouter = [
  {
    path: "/",
    children: SspMobileChildrens,
    component: SspMobile,
    beforeEnter: getCurrentUser(store),
  },
  {
    path: "/oidc-callback", // Needs to match redirect_uri in you oidcSettings
    name: "oidcCallback",
    component: OidcCallback,
    meta: {
      isVuexOidcCallback: true,
      isPublic: true,
    },
  },
];
