import { Model } from "@vuex-orm/core";

export default class SiteActivity extends Model {
  static entity = "siteActivity";

  static fields() {
    return {
      id: this.uid(),
      uuid: this.attr(),
      contact: this.attr(null),
      name: this.attr(""),
      startDate: this.attr(""),
      endDate: this.attr(""),
    };
  }
}
