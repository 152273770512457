import AnalysisRequest from "@/models/AnalysisRequest";

export default class ComplementaryAnalysisRequest extends AnalysisRequest {
  static entity = "complementaryAnalysisRequest";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      eurofinsCaseNumber: this.attr(""),
    };
  }
}
