import { Model } from "@vuex-orm/core";
export default class Location extends Model {
  static entity = "location";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Location";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      town: this.attr(""),
      department: this.attr(""),
      commonName: this.attr(""),
      adress: this.attr(""),
      topographicMap: this.attr(""),
      latitude: this.attr(""),
      longitude: this.attr(""),
      generalTopography: this.attr(""),
      averageElevationZ: this.attr(""),
      approximateArea: this.attr(null), // Float
    };
  }
}
