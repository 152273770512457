export default (store) => {
  return async (to, from, next) => {
    // If user already fetched then go to the next() route
    if (store.getters["user/getCurrentUser"]) {
      return next();
    }

    try {
      await store.dispatch("user/initializeCurrentUser");
      return next();
    } catch (err) {
      console.log(err);
      throw new Error("Error when fetching user data");
    }
  };
};
