import { Model } from "@vuex-orm/core";

export default class BaseEnvModel extends Model {
  static fields() {
    return {
      project: this.attr(),
      laboratoryName: this.attr("EUROFINS"),
      samplesSendingDate: this.attr(),
      transportCondition: this.attr("Glacière réfrigérée"),
    };
  }
}
