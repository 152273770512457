import { Model } from "@vuex-orm/core";
export default class WaterPhysicoChemicalComposition extends Model {
  static entity = "WaterPhysicoChemicalComposition";
  static primaryKey = "uuid";

  static protoMessagePrefix = "WaterPhysicoChemicalComposition";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      bookReference: this.attr(""),
      measurementDate: this.attr(""),
      temperature: this.attr(""),
      ph: this.attr(""),
      conductivity: this.attr(""),
      analysisType: this.attr(""),
    };
  }
}
