import { Model } from "@vuex-orm/core";
export default class NeighboringUseElement extends Model {
  static entity = "neighboringUseElement";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      description: this.attr(""),
      siteDistance: this.attr(0),
      position: this.attr(""),
      supposedHydraulicPositioning: this.attr(""),
      remarks: this.attr(""),
      neighboringUse: this.attr(""),
    };
  }
}
