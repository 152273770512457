import { Model } from "@vuex-orm/core";
export default class UsedProductAndWaste extends Model {
  static entity = "usedProductAndWaste";
  static primaryKey = "uuid";

  static protoMessagePrefix = "UsedProductAndWaste";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      usedProductAndWasteDescription: this.attr(""),
      associatedPollutant: this.attr(""),
      conditionsOfStorage: this.attr(""),
      conditionsForRecoveryOrDisposal: this.attr(""),
    };
  }
}
