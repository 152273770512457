const Mission = () =>
  import(/* webpackChunkName: "Mission" */ "@/components/ssp/missions/Mission");

import MissionCommon from "@/router/common-routes/mission/mission-common";
import store from "@/store";
import SSPMissionCommonChildrens from "@/router/common-routes/mission/children/mission-common-childrens";

const setCurrentMission = async (to, from, next) => {
  if (to.params.missionId !== from.params.missionId) {
    await store.dispatch("missions/retrieveMission", to.params.missionId);
  }
  next();
};

export default [
  ...MissionCommon,
  {
    path: ":missionId",
    component: Mission,
    children: SSPMissionCommonChildrens,
    beforeEnter: setCurrentMission,
  },
];
