import { Model } from "@vuex-orm/core";
import getDefaultReportValue from "@/composables/questionnaire/tree-form/report/getDefaultReportValue";

export default class ConceptualDiagram extends Model {
  static entity = "conceptualDiagram";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      questionnaire: this.attr(""),
      conceptualDiagramPictureUuid: this.attr(""),
      isGroundOffSiteFlightOfDust: this.attr(false),
      isGroundOffSiteFoodIngestion: this.attr(false),
      isGroundOffSiteGasInhalation: this.attr(false),
      isGroundOffSiteNotApplicable: this.attr(false),
      isGroundOffSiteSoilIngestion: this.attr(false),
      groundOffSiteUses: this.attr(getDefaultReportValue("groundUses")),
      isGroundOffSiteVolatilization: this.attr(false),
      isGroundOffSiteWaterSteamIngestion: this.attr(false),
      isGroundOnSiteFoodIngestion: this.attr(false),
      isGroundOnSiteGasInhalation: this.attr(false),
      isGroundOnSiteSoilIngestion: this.attr(false),
      groundOnSiteUses: this.attr(getDefaultReportValue("groundUses")),
      isGroundOnSiteWaterSteamIngestion: this.attr(false),
      selectedZones: this.attr([]),
      isSuperficialWaterOffSiteFishIngestion: this.attr(false),
      isSuperficialWaterOffSiteGroundwaterRiverRelationship: this.attr(false),
      isSuperficialWaterOffSiteLocallyGrownFoodIngestion: this.attr(false),
      isSuperficialWaterOffSiteNotApplicable: this.attr(false),
      isSuperficialWaterOffSiteRunoff: this.attr(false),
      superficialWaterOffSiteUses: this.attr(
        getDefaultReportValue("superficialWaterUses")
      ),
      isSuperficialWaterOffSiteVaporInhalation: this.attr(false),
      isSuperficialWaterOffSiteWaterIngestion: this.attr(false),
      isSuperficialWaterOnSiteFishIngestion: this.attr(false),
      isSuperficialWaterOnSiteLocallyGrownFoodIngestion: this.attr(false),
      superficialWaterOnSiteUses: this.attr(
        getDefaultReportValue("superficialWaterUses")
      ),
      isSuperficialWaterOnSiteVaporInhalation: this.attr(false),
      isSuperficialWaterOnSiteWaterIngestion: this.attr(false),
      isUndergroundWaterOffSiteLocallyGrownFoodIngestion: this.attr(false),
      isUndergroundWaterOffSiteMigrationOfCompounds: this.attr(false),
      isUndergroundWaterOffSiteNotApplicable: this.attr(false),
      undergroundWaterOffSiteUses: this.attr(
        getDefaultReportValue("undergroundWaterUses")
      ),
      isUndergroundWaterOffSiteVaporInhalation: this.attr(false),
      isUndergroundWaterOffSiteWaterIngestion: this.attr(false),
      isUndergroundWaterOnSiteLocallyGrownFoodIngestion: this.attr(false),
      undergroundWaterOnSiteUses: this.attr(
        getDefaultReportValue("undergroundWaterUses")
      ),
      isUndergroundWaterOnSiteVaporInhalation: this.attr(false),
      isUndergroundWaterOnSiteWaterIngestion: this.attr(false),
    };
  }
}
