import { Model } from "@vuex-orm/core";

export default class Investigation extends Model {
  static entity = "investigation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      provisionalProgram: this.attr(""),
      localisation: this.attr(""),
      contaminationSource: this.attr(""),
      surveyNumber: this.attr(0),
      depthRequired: this.attr(0),
      compoundToSeek: this.attr({ data: [] }),
    };
  }
}
