import MissionReportsListViewChildren from "./childrens/mission-reports-list-childrens";
import SSPMissionCommonChildrens from "@/router/common-routes/mission/children/mission-common-childrens";

const MissionReport = () =>
  import(
    /* webpackChunkName: "MissionReport" */ "@/components/ssp/missions/mission/MissionReport"
  );

const MissionReportListView = () =>
  import(
    /* webpackChunkName: "MissionReportListView" */ "@/components/ssp/missions/mission/MissionReportsListView.vue"
  );

export default [
  ...SSPMissionCommonChildrens,
  {
    path: "report",
    name: "mission-report",
    component: MissionReport,
  },
  {
    path: "reports-list-view",
    component: MissionReportListView,
    children: MissionReportsListViewChildren,
  },
];
