import { Model } from "@vuex-orm/core";
export default class IdentifiedGroundwaterCatchment extends Model {
  static entity = "identifiedGroundwaterCatchment";
  static primaryKey = "uuid";

  static protoMessagePrefix = "IdentifiedGroundwaterCatchment";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      reference: this.attr(""),
      name: this.attr(""),
      usage: this.attr(""),
      bodyOfWater: this.attr(""),
      siteDistance: this.attr(0),
      relativePosition: this.attr(""),
    };
  }
}
