import { Model } from "@vuex-orm/core";
import Testimony from "./Testimony";

export default class HistoricalItem extends Model {
  static fields() {
    return {
      testimonial: this.belongsTo(Testimony, "uuid"),
      analyticalStandard: this.attr(),
      tracerCompoundList: this.attr([]),
      quantificationLimit: this.attr(),
      justification: this.attr(),
      isSourceTakenIntoAccount: this.attr(),
      label: this.attr(),
    };
  }
}
