import { Model } from "@vuex-orm/core";
import SiteBuilding from "./SiteBuilding";
import SiteActivity from "./SiteActivity";
import { NEW_CUSTOMER_REQUEST, MISTER } from "@/constants/contactForm";

export default class ContactForm extends Model {
  static entity = "contactForm";

  static fields() {
    return {
      id: this.uid(),
      uuid: this.attr(""),
      project: this.attr(""),
      visitId: this.attr(""),
      siteToAnalyzeType: this.attr(NEW_CUSTOMER_REQUEST),
      companyName: this.attr(""),
      companyAddress: this.attr(""),
      companyPostCode: this.attr(""),
      companyCity: this.attr(""),
      gender: this.attr(MISTER),
      firstName: this.attr(""),
      jobTitle: this.attr(""),
      lastName: this.attr(""),
      email: this.attr(""),
      firstPhoneNumber: this.attr(""),
      secondPhoneNumber: this.attr(""),
      commentsOnCustomerAndSites: this.attr(""),
      requestContexts: this.attr([]),
      serviceObjectives: this.attr([]),
      otherRequestContext: this.attr(""),
      otherServiceObjective: this.attr(""),
      isSiteIcpe: this.attr(0),
      icpe: this.attr([]),
      environmentList: this.attr([]),
      landTotalArea: this.attr(""),
      constructedArea: this.attr(""),
      parkArea: this.attr(""),
      siteBuildings: this.hasMany(SiteBuilding, "contact"),
      areaToInvestigate: this.attr(""),
      levelsPresence: this.attr(""),
      siteActivities: this.hasMany(SiteActivity, "contact"),
      equipementsAndStorages: this.attr([]),
      waterPointPresenceOnSite: this.attr(""),
      traceOfPollutionPresence: this.attr(""),
      accidentOccuredOnSite: this.attr(""),
      previsousStudies: this.attr(""),
      isSiteReferencedBasias: this.attr(false),
      isSiteReferencedBASOL: this.attr(false),
      basiasReference: this.attr(""),
      basolReference: this.attr(""),
      currentSiteUsage: this.attr(""),
      futureSiteUsage: this.attr(""),
      studiesCompletionTime: this.attr(""),
      creationDatetime: this.attr(""),
      contactOrigin: this.attr(""),
      visa: this.attr(""),
      isVisitToDo: this.attr(false),
      reason: this.attr(""),
      sendByEmail: this.attr(false),
      sendByMail: this.attr(false),
      missionTypeToAchieve: this.attr(""),
      privilegedInterlocutor: this.attr(""),
      otherMission: this.attr(""),
      interventionCompanyName: this.attr(""),
      interventionAddress: this.attr(""),
      interventionPostCode: this.attr(""),
      interventionCity: this.attr(""),
      interventionContactGender: this.attr(""),
      interventionContactFirstName: this.attr(""),
      interventionContactLastName: this.attr(""),
      interventionContactEmail: this.attr(""),
      interventionContactJobTitle: this.attr(""),
      interventionContactFirstPhoneNumber: this.attr(""),
      interventionContactSecondPhoneNumber: this.attr(""),

      // contact on site
      isSameContact: this.attr(""),
      siteContactCompanyName: this.attr(""),
      siteContactGender: this.attr(""),
      siteContactFirstName: this.attr(""),
      siteContactLastName: this.attr(""),
      siteContactEmail: this.attr(""),
      siteContactJobTitle: this.attr(""),
      siteContactFirstPhoneNumber: this.attr(""),
      siteContactSecondPhoneNumber: this.attr(""),
    };
  }
}
