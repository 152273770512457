import { Model } from "@vuex-orm/core";

export default class CartographyElement extends Model {
  static entity = "cartographyElements";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      number: this.attr(),
      site: this.attr(),
      latitude: this.attr(),
      longitude: this.attr(),
    };
  }
}
