import { Model } from "@vuex-orm/core";

export default class Analysis extends Model {
  static entity = "analysis";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      name: this.attr(),
      code: this.attr(),
      analysisType: this.attr(),
      price: this.attr(),
      isArchived: this.attr(),
    };
  }
}
