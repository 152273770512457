import { Model } from "@vuex-orm/core";

export default class PreparationMaterial extends Model {
  static entity = "preparationMaterial";
  static primaryKey = ["preparationUuid", "materialUuid"];
  static fields() {
    return {
      preparationUuid: this.attr(""),
      materialUuid: this.attr(""),
    };
  }
}
