import { Model } from "@vuex-orm/core";

export default class ImmediateDanger extends Model {
  static entity = "imeddiateDanger";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      site: this.attr(),
      immediateDangerType: this.attr(),
      observation: this.attr(),
      isImmediateDanger: this.attr(),
      createdAt: this.attr(),
      updatedAt: this.attr(),
      securityMeasuresList: this.attr([]),
      recommendedDeadline: this.attr(),
    };
  }
}
