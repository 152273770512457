import { Model } from "@vuex-orm/core";

export default class PreparationWithAnalysis extends Model {
  static entity = "preparation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      project: this.attr(),
      materialsList: this.attr([]),
      validatedMaterials: this.attr([]),
      isValidated: this.attr(false),
      analysisList: this.attr([]),
      totalAnalysis: this.attr(),
      totalAnalysisCost: this.attr(),
    };
  }
}
