import HistoricalItem from "./HistoricalItem";

export default class ICPE extends HistoricalItem {
  static entity = "ICPE";
  static primaryKey = "uuid";

  static fields() {
    return {
      regime: this.attr(),
      startDate: this.attr(),
      activityDone: this.attr(),
      endDate: this.attr(),
      documentType: this.attr(),
      canPollute: this.attr(),
      reference: this.attr(),
      companyName: this.attr(),
      sectionNumber: this.attr(),
      sectionTitle: this.attr(),
      observations: this.attr(),
    };
  }
}
