import AdditionalInformation from "@/models/AdditionalInformation";

export default (store) => {
  return async (to, from, next) => {
    try {
      const currentMissionUuid = store.getters["missions/currentMission"].uuid;
      await store.dispatch(
        "additionalInformation/retrieveAdditionalInformationByMission",
        currentMissionUuid
      );
      return next();
    } catch (err) {
      const additionalInformationToCreate = new AdditionalInformation({
        project: store.getters["missions/currentMission"].uuid,
      });
      try {
        await store.dispatch(
          "additionalInformation/createAdditionalInformation",
          additionalInformationToCreate
        );
        return next();
      } catch (e) {
        console.error(e);
        return next();
      }
    }
  };
};
