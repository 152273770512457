import MissionSiteEnvironmentChildren from "./mission-site-environment-children";
const cartography = () =>
  import(
    /* webpackChunkName: "Cartography" */
    "@/components/ssp/missions/mission/site/Cartography"
  );

const securityAccess = () =>
  import(
    /* webpackChunkName: "SecurityAccess" */
    "@/components/ssp/missions/mission/site/SecurityAccess"
  );

const environment = () =>
  import(
    /* webpackChunkName: "Environment" */
    "@/components/ssp/missions/mission/site/Environment"
  );

const testimonials = () =>
  import(
    /* webpackChunkName: "Testimonials" */
    "@/components/ssp/missions/mission/site/Testimonials"
  );

const offSite = () =>
  import(
    /* webpackChunkName: "OffSite" */
    "@/components/ssp/missions/mission/site/OffSite"
  );

const historicalResearch = () =>
  import(
    /* webpackChunkName: "HistoricalResearch" */
    "@/components/ssp/missions/mission/site/HistoricalResearch"
  );

const synthesis = () =>
  import(
    /* webpackChunkName: "Synthesis" */
    "@/components/ssp/missions/mission/site/Synthesis"
  );

const vulnerability = () =>
  import(
    /* webpackChunkName: "Vulnerability" */
    "@/components/ssp/missions/mission/site/Vulnerability"
  );

export default [
  {
    path: "cartography",
    name: "cartography",
    component: cartography,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "security-access",
    name: "security-access",
    component: securityAccess,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "environment",
    name: "environment",
    component: environment,
    redirect: () => {
      return { name: "OutdoorSpaces" };
    },
    children: MissionSiteEnvironmentChildren,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "testimonials",
    name: "testimonials",
    component: testimonials,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "off-site",
    name: "off-site",
    component: offSite,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "historical-research",
    name: "historical-research",
    component: historicalResearch,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "synthesis",
    name: "synthesis",
    component: synthesis,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
  {
    path: "vulnerability",
    name: "vulnerability",
    component: vulnerability,
    meta: {
      showSubNavBar: true,
      parentRouteName: "site",
    },
  },
];
