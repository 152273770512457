import { Model } from "@vuex-orm/core";
export default class SiteInformation extends Model {
  static entity = "SiteInformations";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      clientName: this.attr(null),
      siteName: this.attr(null),
      address: this.attr(null),
      postcode: this.attr(null),
      city: this.attr(null),
      department: this.attr(null),
      locationPlan: this.attr(null),
      locationPlanName: this.attr(null),
      currentUsage: this.attr(null),
      usageTypologiesList: this.attr(null),
      currentTargetsList: this.attr(null),
      isWasteland: this.attr(null),
      cadastralPlan: this.attr(null),
      cadastralPlanName: this.attr(null),
      totalSurface: this.attr(null),
      isSiteIcpe: this.attr(null),
      currentRegime: this.attr(null),
      typeDocumentAdministratif: this.attr(null),
      date: this.attr(null),
      reference: this.attr(null),
      socialReason: this.attr(null),
      activityStatus: this.attr(null),
      activityStartDate: this.attr(null),
      activityEndDate: this.attr(null),
      cessationAdministrative: this.attr(null),
      cessationDate: this.attr(null),
      project: this.attr(null),
      siteVisitPlan: this.attr(null),
      siteVisitPlanName: this.attr(""),
    };
  }
}
