import { Model } from "@vuex-orm/core";

export default class AnalysisRequest extends Model {
  static entity = "analysisRequest";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      label: this.attr(""),
      resultStatus: this.attr(""),
      sendingStatus: this.attr(""),
      eurofinsStatus: this.attr(""),
      eurofinsRecoveryDate: this.attr(""),
      project: this.attr(""),
      matricesList: this.attr([]),
    };
  }
}
