import { Model } from "@vuex-orm/core";

export default class AosPicture extends Model {
  static entity = "aos_pictures";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(""),
      aosItem: this.attr(""),
      pictureUuid: this.attr(""),
      pictureUrl: this.attr(""),
      pictureName: this.attr(""),
      title: this.attr(""),
      year: this.attr(),
      observation: this.attr(""),
      isMain: this.attr(),
    };
  }
}
