import { Model } from "@vuex-orm/core";

export default class PurgeSoilGasMeasure extends Model {
  static entity = "purgeSoilGasMeasure";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      purge: this.attr(),
      createdAt: this.attr(),
      startDatetime: this.attr(),
      volumePurged: this.number(),
      cov: this.number(),
      methaneRate: this.number(),
      carbonMonoxidePpmv: this.number(),
      carbonDioxideRate: this.number(),
      dioxygenRate: this.number(),
      isVolumePurgedValid: this.boolean(),
      isDioxygenRateValid: this.boolean(),
      isCarbonDioxideRateValid: this.boolean(),
    };
  }
}
