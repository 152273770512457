const documentList = () =>
  import(
    /* webpackChunkName: "MissionDocuement" */ "@/components/ssp/missions/mission/mission-document/DocumentList"
  );

const documentPhoto = () =>
  import(
    /* webpackChunkName: "MissionDocuement" */ "@/components/ssp/missions/mission/mission-document/DocumentPhoto"
  );

export default [
  {
    path: "all",
    name: "documents",
    component: documentList,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "photos",
    name: "photos",
    component: documentPhoto,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "analysis",
    name: "DocumentAnalysis",
    component: documentList,
    meta: {
      showSubNavBar: true,
    },
  },
];
