import { User as BaseUser } from "@socotec.io/socio-vue-components";

export default class User extends BaseUser {
  static name = "User";
  static entity = "users";

  static fields() {
    return {
      ...super.fields(),
    };
  }

  get isExternal() {
    return this.groups.includes("ExternalUser");
  }
}
