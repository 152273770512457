import { Model } from "@vuex-orm/core";
export default class FoodLaboratoryAnalysis extends Model {
  static entity = "foodLaboratoryAnalysis";
  static primaryKey = "uuid";

  static protoMessagePrefix = "FoodLaboratoryAnalysis";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      measurePoint: this.attr(""),
      sampleNumber: this.attr(""),
      substanceOrComposite: this.attr(""),
    };
  }
}
