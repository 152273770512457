import { Model } from "@vuex-orm/core";

export default class SiteBuilding extends Model {
  static entity = "siteBuilding";

  static fields() {
    return {
      id: this.uid(),
      uuid: this.attr(),
      contact: this.attr(null),
      name: this.attr(""),
      activity: this.attr(""),
      area: this.attr(""),
      isPavingOnGround: this.attr(false),
    };
  }
}
