import { Model } from "@vuex-orm/core";

export default class AmbientAirSamplingJustification extends Model {
  static entity = "ambientAirSamplingJustification";
  static primaryKey = "uuid";

  static protoMessagePrefix = "AmbientAirSamplingJustification";

  static fields() {
    return {
      uuid: this.attr(""),
      reportQuestionnaire: this.attr(""),
      structure: this.attr(""),
      localisation: this.attr(""),
      justification: this.attr(""),
    };
  }
}
