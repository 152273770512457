import { Model } from "@vuex-orm/core";
export default class PhotographsPlansAnalysis extends Model {
  static entity = "photographsPlansAnalysis";
  static primaryKey = "uuid";

  static protoMessagePrefix = "PhotographsPlansAnalysis";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      date: this.attr(""),
      document: this.attr(""),
      observations: this.attr(""),
    };
  }
}
