import { Model } from "@vuex-orm/core";

export default class Activity extends Model {
  static entity = "activity";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Activity";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      name: this.attr(""),
      activityType: this.attr(""),
      timePeriod: this.attr(""),
      icpeSection: this.attr(""),
      classification: this.attr(""), // String (IcpeClassification)
    };
  }
}
