import { Model } from "@vuex-orm/core";

export default class NeighbourPlu extends Model {
  static entity = "neighbourPlu";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      zone: this.attr(""),
      description: this.attr(""),
      vulnerability: this.attr(),
    };
  }
}
