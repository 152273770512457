import { Model } from "@vuex-orm/core";
export default class ProductUsedAndRawMaterial extends Model {
  static entity = "productUsedAndRawMaterial";
  static primaryKey = "uuid";

  static protoMessagePrefix = "ProductUsedAndRawMaterial";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      productUsedAndRawMaterialDescription: this.attr(""),
      associatedPollutant: this.attr(""),
      conditionsOfStorage: this.attr(""),
      conditionsOfUsed: this.attr(""),
    };
  }
}
