import { Model } from "@vuex-orm/core";
export default class ActivitiesAndProcessesHistory extends Model {
  static entity = "activitiesAndProcessesHistory";
  static primaryKey = "uuid";

  static protoMessagePrefix = "ActivitiesAndProcessesHistory";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      activitiesAndProcesses: this.attr(""),
      isPotentiallyPolluting: this.attr(false),
      nowOrPast: this.attr(""),
    };
  }
}
