const ReportGenerationSteps = () =>
  import(
    /* webpackChunkName: "ReportGenerationSteps" */ "@/components/ssp/missions/mission/mission-reports-list/ReportGenerationSteps.vue"
  );
const MissionReportsList = () =>
  import(
    /* webpackChunkName: "MissionReportsList" */ "@/components/ssp/missions/mission/mission-reports-list/MissionReportsList.vue"
  );
export default [
  {
    path: "",
    name: "mission-reports-list",
    component: MissionReportsList,
  },
  {
    path: "generation-steps/:templateId/:templateCode/:step/",
    props: true,
    name: "mission-reports-list-generation-step",
    component: ReportGenerationSteps,
  },
];
