const Catalog = () =>
  import(
    /* webpackChunkName: "Catalog" */ "@/components/ssp/eurofins-catalog/Catalog"
  );

export default [
  {
    path: "catalog",
    name: "eurofins-catalog",
    component: Catalog,
  },
];
