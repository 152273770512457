import { Model } from "@vuex-orm/core";

export default class Marker extends Model {
  static entity = "markers";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      parent_uuid: this.attr(),
      long: this.attr(),
      lat: this.attr(),
      markable_id: this.attr(null),
      markable_type: this.attr(null),
      markable: this.morphTo("markable_id", "markable_type"),
      markers: this.hasMany(Marker, "parent_uuid"),
    };
  }
}
