import BaseEnvModel from "./BaseEnvModel";

export default class FloorInformation extends BaseEnvModel {
  static entity = "floorInformation";
  static primaryKey = "uuid";

  static fields() {
    return {
      ...super.fields(),
      uuid: this.attr(),
      bottlingTypeList: this.attr([]),
    };
  }

  setProject(projectUuid) {
    this.project = projectUuid;
  }
}
