import { Model } from "@vuex-orm/core";

export default class Advice extends Model {
  static entity = "advice";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Advice";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      environmentToBeSampled: this.attr(""),
      equipmentOrLocationConcerned: this.attr(""),
      numberOfSamples: this.attr(""),
      depth: this.attr(""),
      typeOfMaterialToUse: this.attr(""),
      suspectedPollutants: this.attr(""),
    };
  }
}
