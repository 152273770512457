import { Model } from "@vuex-orm/core";

export default class LithologyInformation extends Model {
  static entity = "lithologyInformation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      vulnerability: this.attr(),
      nature: this.attr(""),
      top: this.attr(),
      bottom: this.attr(),
    };
  }
}
