import SSPMobileMissionsChildrens from "./ssp-mobile/ssp-mobile-missions-childrens";
import SSPCommonChildrens from "@/router/common-routes/common-childrens";

const Missions = () =>
  import(/* webpackChunkName: "Missions" */ "@/components/ssp/Missions");

export default [
  ...SSPCommonChildrens,
  {
    path: "",
    name: "home",
    redirect: { path: "missions" },
  },
  {
    path: "missions",
    children: SSPMobileMissionsChildrens,
    component: Missions,
  },
  {
    path: "technical-offer",
    name: "technical-offer",
    component: () =>
      import(
        /* webpackChunkName: "TechnicalOffer" */ "@/components/ssp/TechnicalOffer"
      ),
  },
];
