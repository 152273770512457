import { Model } from "@vuex-orm/core";
export default class Weather extends Model {
  static entity = "weather";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Weather";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      date: this.attr(null),
      minimumTemperature: this.number(),
      maximumTemperature: this.number(),
      precipitation: this.number(),
      atmosphericPressure: this.number(),
      analysisType: this.attr(""),
    };
  }
}
