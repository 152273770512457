import { Model } from "@vuex-orm/core";
export default class SuperficialWaterLaboratoryAnalysis extends Model {
  static entity = "superficialWaterLaboratoryAnalysis";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SuperficialWaterLaboratoryAnalysis";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      measurePoint: this.attr(""),
      sampleNumber: this.attr(""),
      substanceOrComposite: this.attr(""),
    };
  }
}
