import MissionsChildrens from "./ssp/missions-childrens";
import MaterialsChildrens from "./ssp/materials-childrens";
import EurofinsCatalogChildrens from "./ssp/eurofins-catalog-childrens";
import SSPCommonChildrens from "@/router/common-routes/common-childrens";

const CasesList = () =>
  import(
    /* webpackChunkName: "CasesList" */ "@/components/ssp/cases/CasesList"
  );

const Missions = () =>
  import(/* webpackChunkName: "Missions" */ "@/components/ssp/Missions");

const Materials = () =>
  import(/* webpackChunkName: "Materials" */ "@/components/ssp/Materials");

const EurofinsCatalog = () =>
  import(
    /* webpackChunkName: "EurofinsCatalog" */ "@/components/ssp/EurofinsCatalog"
  );

const Admin = () =>
  import(/* webpackChunkName: "Admin" */ "@/components/ssp/Admin");

const TechnicalOffer = () =>
  import(
    /* webpackChunkName: "TechnicalOffer" */ "@/components/ssp/TechnicalOffer"
  );

export default [
  ...SSPCommonChildrens,
  {
    path: "",
    name: "home",
    // INFO - M.S - 22/04/2021
    // This redirection for fix the active class that is always active on "/"
    redirect: { name: "cases" },
  },
  {
    path: "cases",
    name: "cases",
    component: CasesList,
  },
  {
    path: "missions",
    children: MissionsChildrens,
    component: Missions,
  },
  {
    path: "materials",
    children: MaterialsChildrens,
    component: Materials,
  },
  {
    path: "eurofins-catalog",
    children: EurofinsCatalogChildrens,
    component: EurofinsCatalog,
  },
  {
    path: "technical-offer",
    name: "technicalOffer",
    component: TechnicalOffer,
  },
  {
    path: "admin",
    name: "admin",
    component: Admin,
  },
];
