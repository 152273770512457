import { Model } from "@vuex-orm/core";

export default class Incident extends Model {
  static entity = "incident";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Incident";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      date: this.attr(""), // Date
      eventType: this.attr(""),
      equipmentConcerned: this.attr(""),
      mainOrigin: this.attr(""),
      mainSigns: this.attr(""),
      hasAtmosphericPollution: this.attr(null), // Boolean
      atmosphericPollutionCharacteristics: this.attr(""),
      hasPollutionOfSurfaceWater: this.attr(null), // Boolean
      pollutionOfSurfaceWaterCharacteristics: this.attr(""),
      hasGroundwaterPollution: this.attr(null), // Boolean
      groundwaterPollutionCharacteristics: this.attr(""),
      hasSoilPollution: this.attr(null), // Boolean
      soilPollutionCharacteristics: this.attr(""),
      hasPresenceOfLagoons: this.attr(null), // Boolean
      presenceOfLagoonsCharacteristics: this.attr(""),
      actionsTakenList: this.attr([]), // Array<String>
    };
  }
}
