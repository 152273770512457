import { Model } from "@vuex-orm/core";
export default class PersonMet extends Model {
  static entity = "person_met";
  static primaryKey = "uuid";

  static protoMessagePrefix = "PersonMet";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      name: this.attr(""),
      organization: this.attr(""),
      phone: this.attr(""),
      meetDate: this.attr(""), // Date
    };
  }
}
