import { Model } from "@vuex-orm/core";

import Marker from "./Marker";
import Mission from "../Mission";

export default class AosSite extends Model {
  static entity = "aos_sites";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      projectsList: this.attr(null),
      marker: this.morphOne(Marker, "markable_id", "markable_type"),
      projects: this.hasManyBy(Mission, "projectsList"),
      manualLat: this.attr(),
      manualLong: this.attr(),
    };
  }
}
