import HistoricalItem from "./HistoricalItem";

export default class Restriction extends HistoricalItem {
  static entity = "restriction";
  static primaryKey = "uuid";

  static fields() {
    return {
      type: this.attr(),
      documentType: this.attr(),
      documentPhoto: this.attr(),
      description: this.attr(),
    };
  }
}
