import { Model } from "@vuex-orm/core";
import Material from "@/models/Materials";
import AdditionalMaterial from "@/models/AdditionalMaterial";
import Analysis from "@/models/Analysis";

export default class Preparation extends Model {
  static entity = "preparation";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.uid(""),
      mission: this.attr(),
      materials: this.attr([]),
      materialsData: this.hasManyBy(Material, "materials"),
      validatedMaterials: this.attr([]),
      validatedMaterialsData: this.hasManyBy(Material, "validatedMaterials"),
      isValidated: this.attr(false),
      additionalsMaterial: this.hasMany(AdditionalMaterial, "preparation"),
      analysis: this.attr([]),
      analysisData: this.hasManyBy(Analysis, "analysis"),
    };
  }
}
