import Preparation from "@/models/Preparation";

export default (store) => {
  return async (to, from, next) => {
    try {
      const currentMissionUuid = store.getters["missions/currentMission"].uuid;
      await store.dispatch(
        "preparation/retrievePreparationByMission",
        currentMissionUuid
      );
      return next();
    } catch (err) {
      const preparationToCreate = new Preparation({
        mission: store.getters["missions/currentMission"].uuid,
      });
      try {
        await store.dispatch(
          "preparation/createPreparation",
          preparationToCreate
        );
        return next();
      } catch (e) {
        console.error(e);
        return next();
      }
    }
  };
};
