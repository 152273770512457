import { Model } from "@vuex-orm/core";

export default class ExcavatedGroundAnalysisProgram extends Model {
  static entity = "excavatedGroundAnalysisProgram";
  static primaryKey = "uuid";

  static protoMessagePrefix = "ExcavatedGroundAnalysisProgram";

  static fields() {
    return {
      uuid: this.attr(""),
      reportQuestionnaire: this.attr(""),
      sample: this.attr(),
    };
  }
}
