import { Model } from "@vuex-orm/core";
export default class Section extends Model {
  static entity = "sections";
  static primaryKey = "uuid";

  static fields() {
    return {
      uuid: this.attr(),
      sectionNumber: this.attr(),
      label: this.attr(""),
      icpeClassification: this.attr(""),
      observation: this.attr(""),
    };
  }
}
