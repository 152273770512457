import { Model } from "@vuex-orm/core";
export default class GeologicalWorkNearSite extends Model {
  static entity = "geologicalWorkNearSite";
  static primaryKey = "uuid";

  static protoMessagePrefix = "GeologicalWorkNearSite";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      reference: this.attr(""),
      usage: this.attr(""),
      siteDistance: this.attr(""),
      relativePosition: this.attr(""),
    };
  }
}
