import { Model } from "@vuex-orm/core";
export default class AdministrativeSituationsHistory extends Model {
  static entity = "administrativeSituationsHistory";
  static primaryKey = "uuid";

  static protoMessagePrefix = "AdministrativeSituationsHistory";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      companyNameOperator: this.attr(""),
      regime: this.attr(""),
      orderReceiptDate: this.attr(""),
      orderReceiptReference: this.attr(""),
      heading: this.attr(""),
    };
  }
}
