import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import { vuexOidcCreateRouterMiddleware } from "vuex-oidc";
import { desktopRouter } from "@/router/desktop";
import { mobileRouter } from "@/router/mobile";

Vue.use(VueRouter);

let routes = desktopRouter;

if (
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )
) {
  routes = mobileRouter;
}

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(vuexOidcCreateRouterMiddleware(store, "oidc"));

export default router;
