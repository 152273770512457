import { Model } from "@vuex-orm/core";

export default class Landfill extends Model {
  static entity = "landfill";
  static primaryKey = "uuid";

  static protoMessagePrefix = "Landfill";

  static fields() {
    return {
      uuid: this.uid(),
      visitQuestionnaire: this.attr(""),
      denomination: this.attr(""),
      garbageTypeList: this.attr([]), // Array<String>
      packaging: this.attr(""),
      waterproofing: this.attr(""),
      volume: this.attr(""),
      access: this.attr(""),
      garbagesIdentified: this.attr(""),
      specificsHazards: this.attr(""),
      depositStability: this.attr(true), // Boolean
      aggravatingFactor: this.attr(""),
    };
  }
}
