import { Model } from "@vuex-orm/core";
export default class SamplingTimeAndFlowRate extends Model {
  static entity = "samplingTimeAndFlowRate";
  static primaryKey = "uuid";

  static protoMessagePrefix = "SamplingTimeAndFlowRate";

  static fields() {
    return {
      uuid: this.uid(),
      reportQuestionnaire: this.attr(""),
      composite: this.attr(""),
      analyticalArea: this.attr(""),
      controlArea: this.attr(""),
      thresholdToReach: this.attr(""),
      samplingFlowRate: this.attr(""),
      minimumVolume: this.attr(""),
      samplingTime: this.attr(""),
      analysisType: this.attr(""),
    };
  }
}
