import { Model } from "@vuex-orm/core";
import Marker from "./Marker";

export default class Component extends Model {
  static entity = "components";
  static primaryKey = "uuid";
  static aosType = "COMPONENT";

  static fields() {
    return {
      uuid: this.attr(),
      label: this.attr(),
      type: this.attr(null),
      usageList: this.attr([]),
      contentList: this.attr([]),
      masonryPit: this.attr(null),
      position: this.attr(null),
      locationInerted: this.attr(null),
      inertageDate: this.attr(null),
      remplacementDate: this.attr(null),
      inertageMethodList: this.attr([]),
      isRetention: this.boolean(null),
      waterproofSurface: this.attr(null),
      temporality: this.attr(null),
      toolTypeList: this.attr([]),
      impactedEnvironmentList: this.attr([]),
      fuelEnergyList: this.attr([]),
      dielectricPresence: this.attr(null),
      dielectricList: this.attr([]),
      storageTypeList: this.attr([]),
      description: this.attr(null),
      backfillType: this.attr(null),
      nature: this.attr(null),
      areaPositioning: this.attr(null),
      burnedProducts: this.attr(null),
      surface: this.attr(null),
      isWaterproof: this.boolean(null),
      usedFluidsList: this.attr([]),
      state: this.attr(null),
      packagingList: this.attr([]),
      usedSolvant: this.attr(null),
      productTypesList: this.attr([]),
      structureDepth: this.attr(null),
      height: this.attr(null),
      wastesTypeList: this.attr([]),
      sphcConnected: this.attr(null),
      underTension: this.attr(null),
      waterDepth: this.attr(null),
      integratedTank: this.attr(null),
      isKnownDepth: this.boolean(null),
      isCompartment: this.boolean(null),
      depth: this.attr(null),
      volume: this.attr(null),
      width: this.attr(null),
      length: this.attr(null),
      rejectionList: this.attr([]),
      isRemoteUnloading: this.boolean(null),
      rejectionLocation: this.attr(null),
      materialsList: this.attr([]),
      doubleSkin: this.attr(null),
      installationDate: this.attr(null),
      isPollutionTraces: this.boolean(false),
      remarks: this.attr(null),
      pictureUuid: this.attr(null),
      tagsTypeList: this.attr([]),
      typology: this.attr("COMPONENT"),
      details: this.attr(null),
      tracerCompoundList: this.attr([]),
      analyticalStandard: this.attr(null),
      quantificationLimit: this.attr(null),
      isSourceTakenIntoAccount: this.boolean(true),
      justification: this.attr(null),
      diameter: this.attr(null),
      removalDate: this.attr(null),
      collectedEffluent: this.attr(null),
      presenceOfSludge: this.attr(null),
      locationInsideBuilding: this.attr(null),
      locationInsideZone: this.attr(null),
      reference: this.attr(null),
      marker: this.morphOne(Marker, "markable_id", "markable_type"),
    };
  }
}
