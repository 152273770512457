const MissionDashboard = () =>
  import(
    /* webpackChunkName: "MissionDashboard" */
    "@/components/ssp/missions/mission/MissionDashboard"
  );

const MissionIdentityCard = () =>
  import(
    /* webpackChunkName: "MissionIdentityCard" */
    "@/components/ssp/missions/mission/MissionIdentityCard"
  );

import MissionIdentityCardChildren from "@/router/desktop/childrens/ssp/missions/childrens/mission-identity-card-childrens";

const MissionContributors = () =>
  import(
    /* webpackChunkName: "MissionContributors" */
    "@/components/ssp/missions/mission/MissionContributors"
  );

const MissionIntervention = () =>
  import(
    /* webpackChunkName: "MissionIntervention" */
    "@/components/ssp/missions/mission/MissionIntervention"
  );

const MissionPreparation = () =>
  import(
    /* webpackChunkName: "MissionPreparation" */
    "@/components/ssp/missions/mission/MissionPreparation"
  );

import MissionPreparationChildren from "@/router/desktop/childrens/ssp/missions/childrens/mission-preparation-childrens";

const MissionDocument = () =>
  import(
    /* webpackChunkName: "MissionReportsList" */
    "@/components/ssp/missions/mission/MissionDocument"
  );

const Site = () =>
  import(
    /* webpackChunkName: "Site" */
    "@/components/ssp/missions/mission/Site"
  );

const Schema = () =>
  import(
    /* webpackChunkName: "Schema" */
    "@/components/ssp/missions/mission/Schema"
  );

const MissionConfig = () =>
  import(
    /* webpackChunkName: "MissionConfig" */ "@/components/ssp/missions/mission/MissionConfig"
  );

import MissionInterventionChildren from "@/router/desktop/childrens/ssp/missions/childrens/mission-intervention-childrens";
import getOrCreatePreparation from "@/router/middleware/getOrCreatePreparation";
import getOrCreateAdditionalInformation from "@/router/middleware/getOrCreateAdditionalInformation";
import getCurrentDashboard from "@/router/middleware/getCurrentDashboard";

import store from "@/store";
import MissionDocumentChildren from "@/router/desktop/childrens/ssp/missions/childrens/mission-document-childrens";
import MissionSiteChildrens from "@/router/desktop/childrens/ssp/missions/childrens/mission-site-childrens";

export default [
  {
    path: "",
    name: "mission-dashboard",
    component: MissionDashboard,
    beforeEnter: getCurrentDashboard(store),
  },
  {
    path: "identity-card",
    name: "identity-card",
    component: MissionIdentityCard,
    children: MissionIdentityCardChildren,
    redirect: { name: "case" },
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "preparation",
    name: "mission-preparation",
    component: MissionPreparation,
    children: MissionPreparationChildren,
    beforeEnter: getOrCreatePreparation(store),
  },
  {
    path: "intervention",
    name: "mission-intervention",
    component: MissionIntervention,
    children: MissionInterventionChildren,
    beforeEnter: getOrCreateAdditionalInformation(store),
  },
  {
    path: "documents",
    name: "mission-documents",
    component: MissionDocument,
    children: MissionDocumentChildren,
    redirect: { name: "documents" },
  },
  {
    path: "contributors/:type?",
    name: "mission-contributors",
    component: MissionContributors,
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "site",
    name: "site",
    component: Site,
    children: MissionSiteChildrens,
    redirect: { name: "cartography" },
    meta: {
      showSubNavBar: true,
    },
  },
  {
    path: "schema",
    name: "schema",
    component: Schema,
  },
  {
    path: "config",
    name: "mission-config",
    component: MissionConfig,
  },
];
