import BasePurge from "@/models/Purge/BasePurge";
import PurgeSoilGasMeasure from "@/models/Purge/PurgeSoilGasMeasure";

export default class PurgeSoilGas extends BasePurge {
  static entity = "purgeSoilGas";

  static fields() {
    return {
      ...super.fields(),
      measures: this.hasMany(PurgeSoilGasMeasure, "purge"),
    };
  }
}
